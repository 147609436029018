import store from "@/store";

const routes = [
  {
    path: "/printouts",
    name: "printouts",
    component: function () {
      if (store.state.base.userInfo.permissions.printouts.page === false) {
        return import("@/components/simpleViews/403ForbiddenView");
      } else {
        return import("@/components/printouts/PrintoutView");
      }
    },
  },
  {
    path: "/outages",
    name: "outages",
    component: function () {
      if (store.state.base.userInfo.permissions.outages.page === false) {
        return import("@/components/simpleViews/403ForbiddenView");
      } else {
        return import("@/components/outages/OutagesView");
      }
    },
  },
  {
    path: "/user-settings",
    name: "user settings",
    component: () => import("@/components/userSettings/UserSettingsView"),
  },
  {
    path: "/production-planning",
    name: "production planning",
    component: function () {
      if (
        store.state.base.userInfo.permissions.productionPlanning.page === false
      ) {
        return import("@/components/simpleViews/403ForbiddenView");
      } else {
        return import("@/components/general/EmptyRouterView");
      }
    },
    children: [
      {
        path: "recipes",
        component: () => import("@/components/general/EmptyRouterView"),
        children: [
          {
            path: "",
            component: () =>
              import(
                "@/components/productionPlanning/recipeManagement/recipe/RecipesView"
              ),
          },
          {
            path: ":recipe",
            component: () =>
              import(
                "@/components/productionPlanning/recipeManagement/editRecipe/EditRecipeView"
              ),
            beforeEnter: (to, from, next) => {
              let recipeName = decodeURIComponent(to.params.recipe);
              if (
                store.state.recipe.recipes.some((el) => el.name === recipeName)
              ) {
                store
                  .dispatch("recipe/get", to.params.recipe)
                  .then(() => {
                    store.commit("recipe/SET_IS_USED_IN_JOBS", recipeName);
                    next();
                  })
                  .catch((error) => {
                    console.error("Failed to load data:", error);
                    next(false);
                  });
              } else {
                next({ name: "not-found" });
              }
            },
          },
        ],
      },
      {
        path: "jobs",
        component: () =>
          import("@/components/productionPlanning/jobs/JobsView"),
      },
      {
        path: "scheduling",
        component: () =>
          import("@/components/productionPlanning/scheduling/SchedulingView"),
      },
    ],
  },
  {
    path: "/classification",
    name: "classification",
    component: function () {
      if (store.state.base.userInfo.permissions.classification.page === false) {
        return import("@/components/simpleViews/403ForbiddenView");
      } else {
        return import("@/components/classification/ClassificationView");
      }
    },
  },
  {
    path: "/dashboard",
    component: function () {
      if (store.state.base.userInfo.permissions.dashboard.page === false) {
        return import("@/components/simpleViews/403ForbiddenView");
      } else {
        return import("@/components/general/EmptyRouterView");
      }
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("@/components/dashboard/DashboardView"),
      },
      {
        path: ":machineName",
        name: "dashboard machine",
        component: () => import("@/components/dashboard/stats/GraphView"),
      },
    ],
  },
  {
    path: "/privacy-and-terms",
    name: "privacy-and-terms",
    component: () => import("@/components/simpleViews/PrivacyAndTermsView"),
  },
];

export default routes.map((route) => {
  const meta = {
    is_admin: false,
    requires_auth: true,
  };
  return { ...route, meta };
});
