import { useStore } from "vuex";
import { computed } from "vue";
import { useSetBreakpoints } from "@/composables/useSetBreakpoints";
import SiteHeader from "@/components/header/SiteHeader";
import SideNavigation from "@/components/navigation/SideNavigation";
import { useRoute } from "vue-router";
export default {
  components: {
    SiteHeader,
    SideNavigation
  },
  setup() {
    const store = useStore();
    const {
      isTablet
    } = useSetBreakpoints();
    const route = useRoute();
    const isLogin = computed(() => {
      return route.name === "login";
    });
    const isCollapsed = computed(() => store.state.base.menu.collapsed);
    return {
      isCollapsed,
      isTablet,
      isLogin
    };
  }
};