import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import { mapActions, mapMutations, mapState } from "vuex";
import vClickOutside from "click-outside-vue3";
import { ref } from "vue";
export default {
  components: {
    SidebarMenu
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  setup() {
    let versionStr = process.env.VUE_APP_VERSION;
    if (versionStr !== "deployment" && (!versionStr || !versionStr.includes("/"))) {
      throw new Error(`Invalid VUE_APP_VERSION: ${versionStr}`);
    }
    const appVersion = ref(versionStr === "deployment" ? versionStr : versionStr.split("/")[1]);
    return {
      appVersion
    };
  },
  computed: {
    menu() {
      return [{
        href: {
          path: "/dashboard"
        },
        title: "Dashboard",
        icon: "fa fa-table",
        hidden: !this.permissions.dashboard.page,
        hiddenOnCollapse: true
      }, {
        href: {
          path: "/printouts"
        },
        title: "Printouts",
        icon: "fa fa-print",
        hidden: !this.permissions.printouts.page,
        hiddenOnCollapse: true
      }, {
        href: {
          path: "/outages"
        },
        title: "Outages",
        icon: "fa fa-exclamation-triangle",
        hidden: !this.permissions.outages.page,
        hiddenOnCollapse: true
      }, {
        href: {
          path: "/classification"
        },
        title: "Classification",
        icon: "fa fa-images",
        hidden: !this.permissions.classification.page,
        hiddenOnCollapse: true
      }, {
        title: "Production Planning",
        icon: "fa fa-sitemap",
        hidden: !this.permissions.productionPlanning.page,
        hiddenOnCollapse: true,
        child: [{
          href: {
            path: "/production-planning/recipes"
          },
          title: "Recipes",
          alias: ["/production-planning/recipes/:order", "/production-planning/recipes"]
        }, {
          href: {
            path: "/production-planning/jobs"
          },
          title: "Jobs"
        }
        // {
        //   href: { path: "/production-planning/scheduling" },
        //   title: "Scheduling",
        //   hidden: !this.permissions.productionPlanning.scheduling,
        // },
        ]
      }];
    },

    ...mapState("base", {
      collapsed: state => state.menu.collapsed,
      isTablet: state => state.isTablet,
      admin: state => state.admin,
      permissions: state => state.userInfo.permissions,
      toggleClick: state => state.toggleClick
    })
  },
  methods: {
    ...mapActions("tracking", ["sendTracking"]),
    ...mapMutations("base", ["SET_MENU_COLLAPSE"]),
    ...mapMutations("recipe", ["SET_RECIPE_FILTER"]),
    onCollapse(event) {
      this.SET_MENU_COLLAPSE(event);
    },
    hideMenu() {
      if (!this.collapsed && this.isTablet && !this.toggleClick) {
        this.SET_MENU_COLLAPSE(true);
      }
    },
    onMenuItemClick(event, item) {
      if (item.title !== "Recipes" && item.title !== "Jobs") {
        this.SET_RECIPE_FILTER("");
      }
      this.sendTracking({
        event: "menu_click",
        note: item.title
      });
    }
  }
};