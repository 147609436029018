// source: vialchecker/inspection/checks/base/proto/Geometry.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common_inspection_proto_AggregationType_pb = require('../../../../../common/inspection/proto/AggregationType_pb.js');
goog.object.extend(proto, common_inspection_proto_AggregationType_pb);
var common_inspection_proto_MeasurementRange_pb = require('../../../../../common/inspection/proto/MeasurementRange_pb.js');
goog.object.extend(proto, common_inspection_proto_MeasurementRange_pb);
var vialchecker_inspection_checks_base_proto_Info_pb = require('../../../../../vialchecker/inspection/checks/base/proto/Info_pb.js');
goog.object.extend(proto, vialchecker_inspection_checks_base_proto_Info_pb);
goog.exportSymbol('proto.check.Geometry', null, global);
goog.exportSymbol('proto.check.Geometry.AdditionalConfiguration', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.check.Geometry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.check.Geometry.repeatedFields_, null);
};
goog.inherits(proto.check.Geometry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.check.Geometry.displayName = 'proto.check.Geometry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.check.Geometry.AdditionalConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.check.Geometry.AdditionalConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.check.Geometry.AdditionalConfiguration.displayName = 'proto.check.Geometry.AdditionalConfiguration';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.check.Geometry.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.check.Geometry.prototype.toObject = function(opt_includeInstance) {
  return proto.check.Geometry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.check.Geometry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.check.Geometry.toObject = function(includeInstance, msg) {
  var f, obj = {
    internalName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    info: (f = msg.getInfo()) && vialchecker_inspection_checks_base_proto_Info_pb.Info.toObject(includeInstance, f),
    aggregationType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    measurementRange: (f = msg.getMeasurementRange()) && common_inspection_proto_MeasurementRange_pb.MeasurementRange.toObject(includeInstance, f),
    additionalConfigurationByIdMap: (f = msg.getAdditionalConfigurationByIdMap()) ? f.toObject(includeInstance, proto.check.Geometry.AdditionalConfiguration.toObject) : [],
    tagsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.check.Geometry}
 */
proto.check.Geometry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.check.Geometry;
  return proto.check.Geometry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.check.Geometry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.check.Geometry}
 */
proto.check.Geometry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 2:
      var value = new vialchecker_inspection_checks_base_proto_Info_pb.Info;
      reader.readMessage(value,vialchecker_inspection_checks_base_proto_Info_pb.Info.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 3:
      var value = /** @type {!proto.inspection.AggregationType} */ (reader.readEnum());
      msg.setAggregationType(value);
      break;
    case 4:
      var value = new common_inspection_proto_MeasurementRange_pb.MeasurementRange;
      reader.readMessage(value,common_inspection_proto_MeasurementRange_pb.MeasurementRange.deserializeBinaryFromReader);
      msg.setMeasurementRange(value);
      break;
    case 5:
      var value = msg.getAdditionalConfigurationByIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.check.Geometry.AdditionalConfiguration.deserializeBinaryFromReader, "", new proto.check.Geometry.AdditionalConfiguration());
         });
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.check.Geometry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.check.Geometry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.check.Geometry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.check.Geometry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vialchecker_inspection_checks_base_proto_Info_pb.Info.serializeBinaryToWriter
    );
  }
  f = message.getAggregationType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMeasurementRange();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_inspection_proto_MeasurementRange_pb.MeasurementRange.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalConfigurationByIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.check.Geometry.AdditionalConfiguration.serializeBinaryToWriter);
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.check.Geometry.AdditionalConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.check.Geometry.AdditionalConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.check.Geometry.AdditionalConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.check.Geometry.AdditionalConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.check.Geometry.AdditionalConfiguration}
 */
proto.check.Geometry.AdditionalConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.check.Geometry.AdditionalConfiguration;
  return proto.check.Geometry.AdditionalConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.check.Geometry.AdditionalConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.check.Geometry.AdditionalConfiguration}
 */
proto.check.Geometry.AdditionalConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.check.Geometry.AdditionalConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.check.Geometry.AdditionalConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.check.Geometry.AdditionalConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.check.Geometry.AdditionalConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.check.Geometry.AdditionalConfiguration.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.check.Geometry.AdditionalConfiguration} returns this
 */
proto.check.Geometry.AdditionalConfiguration.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.check.Geometry.AdditionalConfiguration.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.check.Geometry.AdditionalConfiguration} returns this
 */
proto.check.Geometry.AdditionalConfiguration.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string internal_name = 1;
 * @return {string}
 */
proto.check.Geometry.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.check.Geometry} returns this
 */
proto.check.Geometry.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Info info = 2;
 * @return {?proto.check.Info}
 */
proto.check.Geometry.prototype.getInfo = function() {
  return /** @type{?proto.check.Info} */ (
    jspb.Message.getWrapperField(this, vialchecker_inspection_checks_base_proto_Info_pb.Info, 2));
};


/**
 * @param {?proto.check.Info|undefined} value
 * @return {!proto.check.Geometry} returns this
*/
proto.check.Geometry.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.check.Geometry} returns this
 */
proto.check.Geometry.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.check.Geometry.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional inspection.AggregationType aggregation_type = 3;
 * @return {!proto.inspection.AggregationType}
 */
proto.check.Geometry.prototype.getAggregationType = function() {
  return /** @type {!proto.inspection.AggregationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.inspection.AggregationType} value
 * @return {!proto.check.Geometry} returns this
 */
proto.check.Geometry.prototype.setAggregationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional inspection.MeasurementRange measurement_range = 4;
 * @return {?proto.inspection.MeasurementRange}
 */
proto.check.Geometry.prototype.getMeasurementRange = function() {
  return /** @type{?proto.inspection.MeasurementRange} */ (
    jspb.Message.getWrapperField(this, common_inspection_proto_MeasurementRange_pb.MeasurementRange, 4));
};


/**
 * @param {?proto.inspection.MeasurementRange|undefined} value
 * @return {!proto.check.Geometry} returns this
*/
proto.check.Geometry.prototype.setMeasurementRange = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.check.Geometry} returns this
 */
proto.check.Geometry.prototype.clearMeasurementRange = function() {
  return this.setMeasurementRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.check.Geometry.prototype.hasMeasurementRange = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * map<string, AdditionalConfiguration> additional_configuration_by_id = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.check.Geometry.AdditionalConfiguration>}
 */
proto.check.Geometry.prototype.getAdditionalConfigurationByIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.check.Geometry.AdditionalConfiguration>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.check.Geometry.AdditionalConfiguration));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.check.Geometry} returns this
 */
proto.check.Geometry.prototype.clearAdditionalConfigurationByIdMap = function() {
  this.getAdditionalConfigurationByIdMap().clear();
  return this;};


/**
 * repeated string tags = 7;
 * @return {!Array<string>}
 */
proto.check.Geometry.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.check.Geometry} returns this
 */
proto.check.Geometry.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.check.Geometry} returns this
 */
proto.check.Geometry.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.check.Geometry} returns this
 */
proto.check.Geometry.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


goog.object.extend(exports, proto.check);
