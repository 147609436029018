const routes = [
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/components/admin/AdminView"),
  },
];

export default routes.map((route) => {
  const meta = {
    is_admin: true,
    requires_auth: true,
  };
  return { ...route, meta };
});
