import { grpcApiUrl } from "@/helper";
import { JobManagementPromiseClient } from "@/pb/isotronic/hosted/stores/productionplanning/rpc/proto/job/JobManagementService_grpc_web_pb.js";

import {
  GetRequest,
  PutRequest,
  ListRequest,
  DeleteRequest,
} from "@/pb/isotronic/hosted/stores/productionplanning/rpc/proto/job/JobManagementService_pb.js";
import { Job } from "@/pb/isotronic/hosted/stores/productionplanning/rpc/proto/job/Job_pb.js";
import options from "@/interceptors";

const list = ({ commit, rootState }, data) => {
  if (rootState.jobPlanning.jobs.length > 0) {
    return;
  }
  return new Promise((resolve, reject) => {
    let jobClient = new JobManagementPromiseClient(grpcApiUrl(), null, options);
    let req = new ListRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    //NOTE: Currently these dont do anything - we don't have a concept in place for running planned and completed
    req.setIncludeRunning(data.running);
    req.setIncludePlanned(data.planned);
    req.setIncludeCompleted(data.completed);
    if (data.machine) {
      req.setMachine(data.machine);
    }
    req.setPreloadRecipeChecks(false);
    jobClient.list(req, {}).then(
      (response) => {
        let jobList = response.toObject().jobsList;
        commit("SET_JOBS", jobList);
        resolve();
      },
      (error) => {
        console.error("list jobs error", error);
        reject(error);
      }
    );
  });
};

const createJob = ({ rootState, commit }, form) => {
  return new Promise((resolve, reject) => {
    let jobClient = new JobManagementPromiseClient(grpcApiUrl(), null, options);
    let req = new PutRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);

    let job = new Job();
    job.setName(form.job_name);
    job.setRecipeName(form.recipe_name);
    job.setNominalQuantity(form.nominal_quantity);

    let meta = new Job.MetaInfo();
    meta.setForeignCustomerName(form.foreign_customer_name);
    meta.setForeignCustomerOrderNumber(form.foreign_customer_order_number);
    meta.setDescription(form.description);
    meta.setGlassType(form.glass_type);
    job.setMetaInfo(meta);

    let planning = new Job.Planning();
    planning.setMachineName(form.machine_name);
    job.setPlanning(planning);

    let productionSetup = new Job.ProductionSetup();
    productionSetup.setPackCountIncrement(form.pack_count);
    productionSetup.setTargetVialsPerHour(form.vials_per_minute * 60);
    job.setProductionSetup(productionSetup);
    req.setJob(job);

    jobClient.put(req, {}).then(
      () => {
        const createdJobName = form.job_name; // Modify according to actual response structure
        getJob({ rootState }, createdJobName).then(
          (jobDetails) => {
            commit("ADD_JOB", jobDetails.toObject());
            resolve(jobDetails);
          },
          (error) => {
            console.error("Error retrieving created job", error);
            reject(error);
          }
        );
        resolve();
      },
      (error) => {
        console.error("create job error", error);
        reject(error);
      }
    );
  });
};

const getJob = ({ rootState }, job_name) => {
  return new Promise((resolve) => {
    let jobClient = new JobManagementPromiseClient(grpcApiUrl(), null, options);
    let req = new GetRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setJobName(job_name);

    jobClient.get(req, {}).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        //name doesn't exist in database
        console.log("get job error", error);
      }
    );
  });
};

const deleteJob = ({ rootState, commit }, name) => {
  return new Promise((resolve, reject) => {
    let jobClient = new JobManagementPromiseClient(grpcApiUrl(), null, options);
    let req = new DeleteRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setName(name);

    jobClient.delete(req, {}).then(
      () => {
        commit("DELETE_JOB", name);
        resolve();
      },
      (error) => {
        console.error("delete job error", error);
        reject(error);
      }
    );
  });
};

export default {
  list,
  createJob,
  deleteJob,
  getJob,
};
