import { grpcApiUrl } from "@/helper";
import { TrackingPromiseClient } from "@/pb/isotronic/hosted/stores/feedback/rpc/proto/tracking/Tracking_grpc_web_pb.js";
import { PutTrackingRequest } from "@/pb/isotronic/hosted/stores/feedback/rpc/proto/tracking/Tracking_pb.js";
import options from "@/interceptors";
import google_protobuf_empty_pb from "google-protobuf/google/protobuf/empty_pb";

const sendTracking = ({ rootState }, data) => {
  if (process.env.NODE_ENV === "development") {
    return;
  }
  return new Promise((resolve, reject) => {
    let trackingClient = new TrackingPromiseClient(grpcApiUrl(), null, options);
    let req = new PutTrackingRequest();
    req.setCompany(rootState.base.userInfo.companyUuid);
    req.setEmail(rootState.base.userInfo.email);
    req.setEvent(data.event);
    req.setNote(data.note);

    trackingClient.put(req, {}).then(
      () => {
        resolve();
      },
      (error) => {
        reject(error);
      }
    );
  });
};

const loadLoginData = () => {
  return new Promise((resolve, reject) => {
    let trackingClient = new TrackingPromiseClient(grpcApiUrl(), null, options);
    trackingClient
      .getUserLoginData(new google_protobuf_empty_pb.Empty(), {})
      .then(
        (result) => {
          resolve(result.toObject());
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export default {
  sendTracking,
  loadLoginData,
};
