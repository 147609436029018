import { useStore } from "vuex";
import { useMediaQuery } from "@vueuse/core/index";
import { watchEffect } from "vue";

export function useSetBreakpoints() {
  const store = useStore();

  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 992px)");
  const SET_MOBILE = (value) => store.commit("base/SET_MOBILE", value);
  const SET_TABLET = (value) => store.commit("base/SET_TABLET", value);

  watchEffect(() => {
    SET_TABLET(isTablet.value);
    SET_MOBILE(isMobile.value);
  });

  return {
    isMobile,
    isTablet,
    SET_MOBILE,
    SET_TABLET,
  };
}
