/**
 * @fileoverview gRPC-Web generated client stub for recipe
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.recipe = require('./RecipeDrawingService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.recipe.RecipeDrawingClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.recipe.RecipeDrawingPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.recipe.PutDrawingRequest,
 *   !proto.recipe.GetDrawingMetadataResponse>}
 */
const methodDescriptor_RecipeDrawing_PutFile = new grpc.web.MethodDescriptor(
  '/recipe.RecipeDrawing/PutFile',
  grpc.web.MethodType.UNARY,
  proto.recipe.PutDrawingRequest,
  proto.recipe.GetDrawingMetadataResponse,
  /**
   * @param {!proto.recipe.PutDrawingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.recipe.GetDrawingMetadataResponse.deserializeBinary
);


/**
 * @param {!proto.recipe.PutDrawingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.recipe.GetDrawingMetadataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.recipe.GetDrawingMetadataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.recipe.RecipeDrawingClient.prototype.putFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/recipe.RecipeDrawing/PutFile',
      request,
      metadata || {},
      methodDescriptor_RecipeDrawing_PutFile,
      callback);
};


/**
 * @param {!proto.recipe.PutDrawingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.recipe.GetDrawingMetadataResponse>}
 *     Promise that resolves to the response
 */
proto.recipe.RecipeDrawingPromiseClient.prototype.putFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/recipe.RecipeDrawing/PutFile',
      request,
      metadata || {},
      methodDescriptor_RecipeDrawing_PutFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.recipe.GetDrawingRequest,
 *   !proto.recipe.GetDrawingMetadataResponse>}
 */
const methodDescriptor_RecipeDrawing_GetFileMetadata = new grpc.web.MethodDescriptor(
  '/recipe.RecipeDrawing/GetFileMetadata',
  grpc.web.MethodType.UNARY,
  proto.recipe.GetDrawingRequest,
  proto.recipe.GetDrawingMetadataResponse,
  /**
   * @param {!proto.recipe.GetDrawingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.recipe.GetDrawingMetadataResponse.deserializeBinary
);


/**
 * @param {!proto.recipe.GetDrawingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.recipe.GetDrawingMetadataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.recipe.GetDrawingMetadataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.recipe.RecipeDrawingClient.prototype.getFileMetadata =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/recipe.RecipeDrawing/GetFileMetadata',
      request,
      metadata || {},
      methodDescriptor_RecipeDrawing_GetFileMetadata,
      callback);
};


/**
 * @param {!proto.recipe.GetDrawingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.recipe.GetDrawingMetadataResponse>}
 *     Promise that resolves to the response
 */
proto.recipe.RecipeDrawingPromiseClient.prototype.getFileMetadata =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/recipe.RecipeDrawing/GetFileMetadata',
      request,
      metadata || {},
      methodDescriptor_RecipeDrawing_GetFileMetadata);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.recipe.GetDrawingRequest,
 *   !proto.recipe.GetDrawingResponse>}
 */
const methodDescriptor_RecipeDrawing_GetFile = new grpc.web.MethodDescriptor(
  '/recipe.RecipeDrawing/GetFile',
  grpc.web.MethodType.UNARY,
  proto.recipe.GetDrawingRequest,
  proto.recipe.GetDrawingResponse,
  /**
   * @param {!proto.recipe.GetDrawingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.recipe.GetDrawingResponse.deserializeBinary
);


/**
 * @param {!proto.recipe.GetDrawingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.recipe.GetDrawingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.recipe.GetDrawingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.recipe.RecipeDrawingClient.prototype.getFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/recipe.RecipeDrawing/GetFile',
      request,
      metadata || {},
      methodDescriptor_RecipeDrawing_GetFile,
      callback);
};


/**
 * @param {!proto.recipe.GetDrawingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.recipe.GetDrawingResponse>}
 *     Promise that resolves to the response
 */
proto.recipe.RecipeDrawingPromiseClient.prototype.getFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/recipe.RecipeDrawing/GetFile',
      request,
      metadata || {},
      methodDescriptor_RecipeDrawing_GetFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.recipe.GetDrawingRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_RecipeDrawing_Delete = new grpc.web.MethodDescriptor(
  '/recipe.RecipeDrawing/Delete',
  grpc.web.MethodType.UNARY,
  proto.recipe.GetDrawingRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.recipe.GetDrawingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.recipe.GetDrawingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.recipe.RecipeDrawingClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/recipe.RecipeDrawing/Delete',
      request,
      metadata || {},
      methodDescriptor_RecipeDrawing_Delete,
      callback);
};


/**
 * @param {!proto.recipe.GetDrawingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.recipe.RecipeDrawingPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/recipe.RecipeDrawing/Delete',
      request,
      metadata || {},
      methodDescriptor_RecipeDrawing_Delete);
};


module.exports = proto.recipe;

