const RECIPE_NAME_DUPLICATE = (state) => (name, initialValue) => {
  return (
    name !== initialValue &&
    state.recipes.some((el) => el.name.toUpperCase() === name.toUpperCase())
  );
};

const LONG_NAME_DUPLICATE = (state) => (name, initialValue) => {
  return (
    name !== initialValue &&
    state.currentRecipe.geometryChecksList.some(
      (el) => el.info.description.toUpperCase() === name.toUpperCase()
    )
  );
};

const SHORT_NAME_DUPLICATE = (state) => (name, initialValue) => {
  return (
    name !== initialValue &&
    state.currentRecipe.geometryChecksList.some(
      (el) => el.info.shortDescription.toUpperCase() === name.toUpperCase()
    )
  );
};

export default {
  RECIPE_NAME_DUPLICATE,
  LONG_NAME_DUPLICATE,
  SHORT_NAME_DUPLICATE,
};
