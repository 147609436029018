// source: hosted/central/gatekeeper/manufacturingapi/rpc/proto/MachineService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var hosted_stores_productionstore_rpc_proto_LogStoreService_pb = require('../../../../../../hosted/stores/productionstore/rpc/proto/LogStoreService_pb.js');
goog.object.extend(proto, hosted_stores_productionstore_rpc_proto_LogStoreService_pb);
var hosted_stores_productionstore_rpc_proto_MachineStoreService_pb = require('../../../../../../hosted/stores/productionstore/rpc/proto/MachineStoreService_pb.js');
goog.object.extend(proto, hosted_stores_productionstore_rpc_proto_MachineStoreService_pb);
goog.exportSymbol('proto.manufacturingapi.Job', null, global);
goog.exportSymbol('proto.manufacturingapi.MachineListRequest', null, global);
goog.exportSymbol('proto.manufacturingapi.MachineListResponse', null, global);
goog.exportSymbol('proto.manufacturingapi.MachineRow', null, global);
goog.exportSymbol('proto.manufacturingapi.OutagesRequest', null, global);
goog.exportSymbol('proto.manufacturingapi.OutagesResponse', null, global);
goog.exportSymbol('proto.manufacturingapi.OutagesResponse.MachineInfo', null, global);
goog.exportSymbol('proto.manufacturingapi.OutagesResponse.OutageInfo', null, global);
goog.exportSymbol('proto.manufacturingapi.StatusRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.MachineListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manufacturingapi.MachineListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.MachineListRequest.displayName = 'proto.manufacturingapi.MachineListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.MachineListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manufacturingapi.MachineListResponse.repeatedFields_, null);
};
goog.inherits(proto.manufacturingapi.MachineListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.MachineListResponse.displayName = 'proto.manufacturingapi.MachineListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.MachineRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manufacturingapi.MachineRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.MachineRow.displayName = 'proto.manufacturingapi.MachineRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manufacturingapi.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.Job.displayName = 'proto.manufacturingapi.Job';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.OutagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manufacturingapi.OutagesRequest.repeatedFields_, null);
};
goog.inherits(proto.manufacturingapi.OutagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.OutagesRequest.displayName = 'proto.manufacturingapi.OutagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.OutagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manufacturingapi.OutagesResponse.repeatedFields_, null);
};
goog.inherits(proto.manufacturingapi.OutagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.OutagesResponse.displayName = 'proto.manufacturingapi.OutagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.OutagesResponse.OutageInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manufacturingapi.OutagesResponse.OutageInfo.repeatedFields_, null);
};
goog.inherits(proto.manufacturingapi.OutagesResponse.OutageInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.OutagesResponse.OutageInfo.displayName = 'proto.manufacturingapi.OutagesResponse.OutageInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.OutagesResponse.MachineInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manufacturingapi.OutagesResponse.MachineInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.OutagesResponse.MachineInfo.displayName = 'proto.manufacturingapi.OutagesResponse.MachineInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.StatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manufacturingapi.StatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.StatusRequest.displayName = 'proto.manufacturingapi.StatusRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.MachineListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.MachineListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.MachineListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.MachineListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    company: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeInterval: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.MachineListRequest}
 */
proto.manufacturingapi.MachineListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.MachineListRequest;
  return proto.manufacturingapi.MachineListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.MachineListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.MachineListRequest}
 */
proto.manufacturingapi.MachineListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.MachineListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.MachineListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.MachineListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.MachineListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeInterval();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string company = 1;
 * @return {string}
 */
proto.manufacturingapi.MachineListRequest.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.MachineListRequest} returns this
 */
proto.manufacturingapi.MachineListRequest.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 time_interval = 2;
 * @return {number}
 */
proto.manufacturingapi.MachineListRequest.prototype.getTimeInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.MachineListRequest} returns this
 */
proto.manufacturingapi.MachineListRequest.prototype.setTimeInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manufacturingapi.MachineListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.MachineListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.MachineListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.MachineListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.MachineListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    machinesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.MachineListResponse}
 */
proto.manufacturingapi.MachineListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.MachineListResponse;
  return proto.manufacturingapi.MachineListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.MachineListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.MachineListResponse}
 */
proto.manufacturingapi.MachineListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addMachines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.MachineListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.MachineListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.MachineListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.MachineListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachinesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string machines = 1;
 * @return {!Array<string>}
 */
proto.manufacturingapi.MachineListResponse.prototype.getMachinesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.manufacturingapi.MachineListResponse} returns this
 */
proto.manufacturingapi.MachineListResponse.prototype.setMachinesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.manufacturingapi.MachineListResponse} returns this
 */
proto.manufacturingapi.MachineListResponse.prototype.addMachines = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manufacturingapi.MachineListResponse} returns this
 */
proto.manufacturingapi.MachineListResponse.prototype.clearMachinesList = function() {
  return this.setMachinesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.MachineRow.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.MachineRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.MachineRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.MachineRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    machine: (f = msg.getMachine()) && hosted_stores_productionstore_rpc_proto_MachineStoreService_pb.MachineStatus.toObject(includeInstance, f),
    job: (f = msg.getJob()) && proto.manufacturingapi.Job.toObject(includeInstance, f),
    isEndOfIteration: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.MachineRow}
 */
proto.manufacturingapi.MachineRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.MachineRow;
  return proto.manufacturingapi.MachineRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.MachineRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.MachineRow}
 */
proto.manufacturingapi.MachineRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hosted_stores_productionstore_rpc_proto_MachineStoreService_pb.MachineStatus;
      reader.readMessage(value,hosted_stores_productionstore_rpc_proto_MachineStoreService_pb.MachineStatus.deserializeBinaryFromReader);
      msg.setMachine(value);
      break;
    case 2:
      var value = new proto.manufacturingapi.Job;
      reader.readMessage(value,proto.manufacturingapi.Job.deserializeBinaryFromReader);
      msg.setJob(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEndOfIteration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.MachineRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.MachineRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.MachineRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.MachineRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachine();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      hosted_stores_productionstore_rpc_proto_MachineStoreService_pb.MachineStatus.serializeBinaryToWriter
    );
  }
  f = message.getJob();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.manufacturingapi.Job.serializeBinaryToWriter
    );
  }
  f = message.getIsEndOfIteration();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional productionstore.MachineStatus machine = 1;
 * @return {?proto.productionstore.MachineStatus}
 */
proto.manufacturingapi.MachineRow.prototype.getMachine = function() {
  return /** @type{?proto.productionstore.MachineStatus} */ (
    jspb.Message.getWrapperField(this, hosted_stores_productionstore_rpc_proto_MachineStoreService_pb.MachineStatus, 1));
};


/**
 * @param {?proto.productionstore.MachineStatus|undefined} value
 * @return {!proto.manufacturingapi.MachineRow} returns this
*/
proto.manufacturingapi.MachineRow.prototype.setMachine = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manufacturingapi.MachineRow} returns this
 */
proto.manufacturingapi.MachineRow.prototype.clearMachine = function() {
  return this.setMachine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manufacturingapi.MachineRow.prototype.hasMachine = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Job job = 2;
 * @return {?proto.manufacturingapi.Job}
 */
proto.manufacturingapi.MachineRow.prototype.getJob = function() {
  return /** @type{?proto.manufacturingapi.Job} */ (
    jspb.Message.getWrapperField(this, proto.manufacturingapi.Job, 2));
};


/**
 * @param {?proto.manufacturingapi.Job|undefined} value
 * @return {!proto.manufacturingapi.MachineRow} returns this
*/
proto.manufacturingapi.MachineRow.prototype.setJob = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manufacturingapi.MachineRow} returns this
 */
proto.manufacturingapi.MachineRow.prototype.clearJob = function() {
  return this.setJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manufacturingapi.MachineRow.prototype.hasJob = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_end_of_iteration = 3;
 * @return {boolean}
 */
proto.manufacturingapi.MachineRow.prototype.getIsEndOfIteration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.manufacturingapi.MachineRow} returns this
 */
proto.manufacturingapi.MachineRow.prototype.setIsEndOfIteration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nominalQuantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    packCountIncrement: jspb.Message.getFieldWithDefault(msg, 3, 0),
    goodCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    badCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    packCount: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.Job}
 */
proto.manufacturingapi.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.Job;
  return proto.manufacturingapi.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.Job}
 */
proto.manufacturingapi.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNominalQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPackCountIncrement(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoodCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBadCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPackCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNominalQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPackCountIncrement();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getGoodCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBadCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPackCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.manufacturingapi.Job.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.Job} returns this
 */
proto.manufacturingapi.Job.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 nominal_quantity = 2;
 * @return {number}
 */
proto.manufacturingapi.Job.prototype.getNominalQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.Job} returns this
 */
proto.manufacturingapi.Job.prototype.setNominalQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 pack_count_increment = 3;
 * @return {number}
 */
proto.manufacturingapi.Job.prototype.getPackCountIncrement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.Job} returns this
 */
proto.manufacturingapi.Job.prototype.setPackCountIncrement = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 good_count = 4;
 * @return {number}
 */
proto.manufacturingapi.Job.prototype.getGoodCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.Job} returns this
 */
proto.manufacturingapi.Job.prototype.setGoodCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 bad_count = 5;
 * @return {number}
 */
proto.manufacturingapi.Job.prototype.getBadCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.Job} returns this
 */
proto.manufacturingapi.Job.prototype.setBadCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 pack_count = 6;
 * @return {number}
 */
proto.manufacturingapi.Job.prototype.getPackCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.Job} returns this
 */
proto.manufacturingapi.Job.prototype.setPackCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manufacturingapi.OutagesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.OutagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.OutagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.OutagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.OutagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    company: jspb.Message.getFieldWithDefault(msg, 1, ""),
    machinesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    begin: (f = msg.getBegin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.OutagesRequest}
 */
proto.manufacturingapi.OutagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.OutagesRequest;
  return proto.manufacturingapi.OutagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.OutagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.OutagesRequest}
 */
proto.manufacturingapi.OutagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addMachines(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBegin(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.OutagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.OutagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.OutagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.OutagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMachinesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBegin();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company = 1;
 * @return {string}
 */
proto.manufacturingapi.OutagesRequest.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.OutagesRequest} returns this
 */
proto.manufacturingapi.OutagesRequest.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string machines = 2;
 * @return {!Array<string>}
 */
proto.manufacturingapi.OutagesRequest.prototype.getMachinesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.manufacturingapi.OutagesRequest} returns this
 */
proto.manufacturingapi.OutagesRequest.prototype.setMachinesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.manufacturingapi.OutagesRequest} returns this
 */
proto.manufacturingapi.OutagesRequest.prototype.addMachines = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manufacturingapi.OutagesRequest} returns this
 */
proto.manufacturingapi.OutagesRequest.prototype.clearMachinesList = function() {
  return this.setMachinesList([]);
};


/**
 * optional google.protobuf.Timestamp begin = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.manufacturingapi.OutagesRequest.prototype.getBegin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.manufacturingapi.OutagesRequest} returns this
*/
proto.manufacturingapi.OutagesRequest.prototype.setBegin = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manufacturingapi.OutagesRequest} returns this
 */
proto.manufacturingapi.OutagesRequest.prototype.clearBegin = function() {
  return this.setBegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manufacturingapi.OutagesRequest.prototype.hasBegin = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.manufacturingapi.OutagesRequest.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.manufacturingapi.OutagesRequest} returns this
*/
proto.manufacturingapi.OutagesRequest.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manufacturingapi.OutagesRequest} returns this
 */
proto.manufacturingapi.OutagesRequest.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manufacturingapi.OutagesRequest.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manufacturingapi.OutagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.OutagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.OutagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.OutagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.OutagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outagesList: jspb.Message.toObjectList(msg.getOutagesList(),
    hosted_stores_productionstore_rpc_proto_LogStoreService_pb.Outage.toObject, includeInstance),
    outageBinMap: (f = msg.getOutageBinMap()) ? f.toObject(includeInstance, proto.manufacturingapi.OutagesResponse.OutageInfo.toObject) : [],
    machineBinMap: (f = msg.getMachineBinMap()) ? f.toObject(includeInstance, proto.manufacturingapi.OutagesResponse.MachineInfo.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.OutagesResponse}
 */
proto.manufacturingapi.OutagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.OutagesResponse;
  return proto.manufacturingapi.OutagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.OutagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.OutagesResponse}
 */
proto.manufacturingapi.OutagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hosted_stores_productionstore_rpc_proto_LogStoreService_pb.Outage;
      reader.readMessage(value,hosted_stores_productionstore_rpc_proto_LogStoreService_pb.Outage.deserializeBinaryFromReader);
      msg.addOutages(value);
      break;
    case 2:
      var value = msg.getOutageBinMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.manufacturingapi.OutagesResponse.OutageInfo.deserializeBinaryFromReader, "", new proto.manufacturingapi.OutagesResponse.OutageInfo());
         });
      break;
    case 3:
      var value = msg.getMachineBinMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.manufacturingapi.OutagesResponse.MachineInfo.deserializeBinaryFromReader, "", new proto.manufacturingapi.OutagesResponse.MachineInfo());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.OutagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.OutagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.OutagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.OutagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      hosted_stores_productionstore_rpc_proto_LogStoreService_pb.Outage.serializeBinaryToWriter
    );
  }
  f = message.getOutageBinMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.manufacturingapi.OutagesResponse.OutageInfo.serializeBinaryToWriter);
  }
  f = message.getMachineBinMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.manufacturingapi.OutagesResponse.MachineInfo.serializeBinaryToWriter);
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.OutagesResponse.OutageInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.OutagesResponse.OutageInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    durationList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    sum: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.OutagesResponse.OutageInfo}
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.OutagesResponse.OutageInfo;
  return proto.manufacturingapi.OutagesResponse.OutageInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.OutagesResponse.OutageInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.OutagesResponse.OutageInfo}
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDuration(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.OutagesResponse.OutageInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.OutagesResponse.OutageInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDurationList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getSum();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 duration = 1;
 * @return {!Array<number>}
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.prototype.getDurationList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manufacturingapi.OutagesResponse.OutageInfo} returns this
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.prototype.setDurationList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manufacturingapi.OutagesResponse.OutageInfo} returns this
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.prototype.addDuration = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manufacturingapi.OutagesResponse.OutageInfo} returns this
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.prototype.clearDurationList = function() {
  return this.setDurationList([]);
};


/**
 * optional int64 sum = 2;
 * @return {number}
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.prototype.getSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.OutagesResponse.OutageInfo} returns this
 */
proto.manufacturingapi.OutagesResponse.OutageInfo.prototype.setSum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.OutagesResponse.MachineInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.OutagesResponse.MachineInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.OutagesResponse.MachineInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.OutagesResponse.MachineInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sum: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.OutagesResponse.MachineInfo}
 */
proto.manufacturingapi.OutagesResponse.MachineInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.OutagesResponse.MachineInfo;
  return proto.manufacturingapi.OutagesResponse.MachineInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.OutagesResponse.MachineInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.OutagesResponse.MachineInfo}
 */
proto.manufacturingapi.OutagesResponse.MachineInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.OutagesResponse.MachineInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.OutagesResponse.MachineInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.OutagesResponse.MachineInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.OutagesResponse.MachineInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 sum = 1;
 * @return {number}
 */
proto.manufacturingapi.OutagesResponse.MachineInfo.prototype.getSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.OutagesResponse.MachineInfo} returns this
 */
proto.manufacturingapi.OutagesResponse.MachineInfo.prototype.setSum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated productionstore.Outage outages = 1;
 * @return {!Array<!proto.productionstore.Outage>}
 */
proto.manufacturingapi.OutagesResponse.prototype.getOutagesList = function() {
  return /** @type{!Array<!proto.productionstore.Outage>} */ (
    jspb.Message.getRepeatedWrapperField(this, hosted_stores_productionstore_rpc_proto_LogStoreService_pb.Outage, 1));
};


/**
 * @param {!Array<!proto.productionstore.Outage>} value
 * @return {!proto.manufacturingapi.OutagesResponse} returns this
*/
proto.manufacturingapi.OutagesResponse.prototype.setOutagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.productionstore.Outage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.productionstore.Outage}
 */
proto.manufacturingapi.OutagesResponse.prototype.addOutages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.productionstore.Outage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manufacturingapi.OutagesResponse} returns this
 */
proto.manufacturingapi.OutagesResponse.prototype.clearOutagesList = function() {
  return this.setOutagesList([]);
};


/**
 * map<string, OutageInfo> outage_bin = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.manufacturingapi.OutagesResponse.OutageInfo>}
 */
proto.manufacturingapi.OutagesResponse.prototype.getOutageBinMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.manufacturingapi.OutagesResponse.OutageInfo>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.manufacturingapi.OutagesResponse.OutageInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.manufacturingapi.OutagesResponse} returns this
 */
proto.manufacturingapi.OutagesResponse.prototype.clearOutageBinMap = function() {
  this.getOutageBinMap().clear();
  return this;};


/**
 * map<string, MachineInfo> machine_bin = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.manufacturingapi.OutagesResponse.MachineInfo>}
 */
proto.manufacturingapi.OutagesResponse.prototype.getMachineBinMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.manufacturingapi.OutagesResponse.MachineInfo>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.manufacturingapi.OutagesResponse.MachineInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.manufacturingapi.OutagesResponse} returns this
 */
proto.manufacturingapi.OutagesResponse.prototype.clearMachineBinMap = function() {
  this.getMachineBinMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.StatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.StatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.StatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.StatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    machineName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.StatusRequest}
 */
proto.manufacturingapi.StatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.StatusRequest;
  return proto.manufacturingapi.StatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.StatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.StatusRequest}
 */
proto.manufacturingapi.StatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.StatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.StatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.StatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.StatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMachineName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer = 1;
 * @return {string}
 */
proto.manufacturingapi.StatusRequest.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.StatusRequest} returns this
 */
proto.manufacturingapi.StatusRequest.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string machine_name = 2;
 * @return {string}
 */
proto.manufacturingapi.StatusRequest.prototype.getMachineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.StatusRequest} returns this
 */
proto.manufacturingapi.StatusRequest.prototype.setMachineName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.manufacturingapi);
