const { DateTime } = require("luxon");

const getDefaultState = () => {
  return {
    selectedMachine: "",
    machines: [],
    machineStatus: [],
    manualZoom: false,
    zoom: {
      dataZoomIndex: 0,
      startIndex: null,
      endIndex: null,
      rejectedSum: 0,
      percentRejected: 0,
      sum: 0,
    },
    pageFilterDropdown: {
      start: 0,
      end: 0,
      text: "",
    },
    jobList: {
      jobs: [],
      selectedJob: -1,
      lastUpdate: null,
    },
    shiftData: {
      computedShiftData: [],
      selectedShift: null,
      categoryDates: [],
      valueDataProduced: [],
      valueDataRejected: [],
    },
    trendCard: {
      showRejectRate: true,
      showGeometric: true,
      showCosmetic: true,
    },
    production: {
      selectedBar: null,
      selectedBarIndex: null,
      categoryDates: [],
      valueDataProduced: [],
      valueDataRejected: [],
      valuePercentRejected: [],
      geometricCheckBins: [],
      cosmeticCheckBins: [],
      jobs: [],
      loading: false,
      defectMap: new Map(),
    },
  };
};

function squashDuplicates(inputData, onlySetForShifts) {
  const data = { ...inputData }; // copy data to avoid mutation
  for (let i = 1; i < data.categoryDates.length; i++) {
    if (data.categoryDates[i] === data.categoryDates[i - 1]) {
      data.categoryDates.splice(i, 1);

      data.valueDataProduced[i - 1] =
        data.valueDataProduced[i] + data.valueDataProduced[i - 1];
      data.valueDataProduced.splice(i, 1);

      data.valueDataRejected[i - 1] =
        data.valueDataRejected[i] + data.valueDataRejected[i - 1];
      data.valueDataRejected.splice(i, 1);

      if (onlySetForShifts) {
        continue;
      }

      data.jobs[i - 1] = data.jobs[i - 1] + " / " + data.jobs[i];
      data.jobs.splice(i, 1);

      data.valuePercentRejected[i - 1] =
        (data.valueDataRejected[i - 1] /
          (data.valueDataRejected[i - 1] + data.valueDataProduced[i - 1])) *
        100;
      data.valuePercentRejected.splice(i, 1);

      const combinedCosChecks = data.cosmeticCheckBins[i - 1].concat(
        data.cosmeticCheckBins[i]
      );
      // Remove duplicates and add values together
      const resultCombinedCosChecks = combinedCosChecks.reduce(
        (accumulator, current) => {
          const index = accumulator.findIndex(
            (item) => item.internalName === current.internalName
          );

          if (index >= 0) {
            //if found
            accumulator[index].badCheckCount += current.badCheckCount;
          } else {
            accumulator.push(current);
          }

          return accumulator;
        },
        []
      );
      data.cosmeticCheckBins.splice(i, 1);
      data.cosmeticCheckBins[i - 1] = resultCombinedCosChecks;

      const combinedGeoChecks = data.geometricCheckBins[i - 1].concat(
        data.geometricCheckBins[i]
      );
      // Remove duplicates and add values together
      const resultCombinedGeoChecks = combinedGeoChecks.reduce(
        (accumulator, current) => {
          const index = accumulator.findIndex(
            (item) => item.internalName === current.internalName
          );

          if (index >= 0) {
            //if found
            accumulator[index].badAboveNominal += current.badAboveNominal;
            accumulator[index].badBelowNominal += current.badBelowNominal;
          } else {
            accumulator.push(current);
          }

          return accumulator;
        },
        []
      );

      data.geometricCheckBins.splice(i, 1);
      data.geometricCheckBins[i - 1] = resultCombinedGeoChecks;
    }
  }
  return data;
}
function addZeroValues(
  inputData,
  timeZone,
  timeStart,
  timeEnd,
  onlySetForShifts
) {
  const data = { ...inputData }; // copy data to avoid mutation

  // since product bins aren't created when machines aren't producing anything,
  // inject zero values in for missing dates
  let begin = DateTime.fromSeconds(timeStart.getSeconds()).setZone(
    timeZone || "utc"
  );
  const remainder = begin.minute % 30;
  let normalizedBegin = begin.minus({ minutes: remainder }).startOf("minute");
  let end =
    timeEnd && timeEnd.getSeconds() !== 0
      ? DateTime.fromSeconds(timeEnd.getSeconds()).setZone(timeZone || "utc")
      : DateTime.utc().setZone(timeZone || "utc");
  let newData = [];
  while (normalizedBegin < end) {
    newData.push(normalizedBegin.toFormat("yyyy-LL-dd HH:mm"));
    normalizedBegin = normalizedBegin.plus({ minutes: 30 });
  }
  for (let i = 0; i < newData.length; i++) {
    if (newData[i] !== data.categoryDates[i]) {
      data.categoryDates.splice(i, 0, newData[i]);
      data.valueDataProduced.splice(i, 0, 0);
      data.valueDataRejected.splice(i, 0, 0);
      if (onlySetForShifts) {
        continue;
      }
      data.jobs.splice(i, 0, 0);
      data.valuePercentRejected.splice(i, 0, 0);
      data.geometricCheckBins.splice(i, 0, []);
      data.cosmeticCheckBins.splice(i, 0, []);
    }
  }
  return data;
}
function convertTime(t, format = "LLL dd, yyyy hh:mm a ZZZ", timeZone) {
  let luxonTime = DateTime.fromMillis(t.seconds * 1000 + t.nanos / 1000000, {
    zone: "utc",
  });
  if (timeZone) {
    luxonTime = luxonTime.setZone(timeZone);
  }
  return luxonTime.toFormat(format);
}

module.exports = {
  getDefaultState,
  squashDuplicates,
  addZeroValues,
  convertTime,
};
