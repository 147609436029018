/**
 * @fileoverview gRPC-Web generated client stub for manufacturingapi
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var hosted_stores_productionstore_rpc_proto_LogStoreService_pb = require('../../../../../../hosted/stores/productionstore/rpc/proto/LogStoreService_pb.js')

var hosted_stores_productionstore_rpc_proto_MachineStoreService_pb = require('../../../../../../hosted/stores/productionstore/rpc/proto/MachineStoreService_pb.js')
const proto = {};
proto.manufacturingapi = require('./MachineService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.manufacturingapi.MachineClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.manufacturingapi.MachinePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manufacturingapi.MachineListRequest,
 *   !proto.manufacturingapi.MachineRow>}
 */
const methodDescriptor_Machine_StreamList = new grpc.web.MethodDescriptor(
  '/manufacturingapi.Machine/StreamList',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.manufacturingapi.MachineListRequest,
  proto.manufacturingapi.MachineRow,
  /**
   * @param {!proto.manufacturingapi.MachineListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manufacturingapi.MachineRow.deserializeBinary
);


/**
 * @param {!proto.manufacturingapi.MachineListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.manufacturingapi.MachineRow>}
 *     The XHR Node Readable Stream
 */
proto.manufacturingapi.MachineClient.prototype.streamList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/manufacturingapi.Machine/StreamList',
      request,
      metadata || {},
      methodDescriptor_Machine_StreamList);
};


/**
 * @param {!proto.manufacturingapi.MachineListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.manufacturingapi.MachineRow>}
 *     The XHR Node Readable Stream
 */
proto.manufacturingapi.MachinePromiseClient.prototype.streamList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/manufacturingapi.Machine/StreamList',
      request,
      metadata || {},
      methodDescriptor_Machine_StreamList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manufacturingapi.StatusRequest,
 *   !proto.productionstore.MachineStatus>}
 */
const methodDescriptor_Machine_Status = new grpc.web.MethodDescriptor(
  '/manufacturingapi.Machine/Status',
  grpc.web.MethodType.UNARY,
  proto.manufacturingapi.StatusRequest,
  hosted_stores_productionstore_rpc_proto_MachineStoreService_pb.MachineStatus,
  /**
   * @param {!proto.manufacturingapi.StatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  hosted_stores_productionstore_rpc_proto_MachineStoreService_pb.MachineStatus.deserializeBinary
);


/**
 * @param {!proto.manufacturingapi.StatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.productionstore.MachineStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.productionstore.MachineStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manufacturingapi.MachineClient.prototype.status =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manufacturingapi.Machine/Status',
      request,
      metadata || {},
      methodDescriptor_Machine_Status,
      callback);
};


/**
 * @param {!proto.manufacturingapi.StatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.productionstore.MachineStatus>}
 *     Promise that resolves to the response
 */
proto.manufacturingapi.MachinePromiseClient.prototype.status =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manufacturingapi.Machine/Status',
      request,
      metadata || {},
      methodDescriptor_Machine_Status);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manufacturingapi.StatusRequest,
 *   !proto.productionstore.MachineStatus>}
 */
const methodDescriptor_Machine_StreamStatus = new grpc.web.MethodDescriptor(
  '/manufacturingapi.Machine/StreamStatus',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.manufacturingapi.StatusRequest,
  hosted_stores_productionstore_rpc_proto_MachineStoreService_pb.MachineStatus,
  /**
   * @param {!proto.manufacturingapi.StatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  hosted_stores_productionstore_rpc_proto_MachineStoreService_pb.MachineStatus.deserializeBinary
);


/**
 * @param {!proto.manufacturingapi.StatusRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.productionstore.MachineStatus>}
 *     The XHR Node Readable Stream
 */
proto.manufacturingapi.MachineClient.prototype.streamStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/manufacturingapi.Machine/StreamStatus',
      request,
      metadata || {},
      methodDescriptor_Machine_StreamStatus);
};


/**
 * @param {!proto.manufacturingapi.StatusRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.productionstore.MachineStatus>}
 *     The XHR Node Readable Stream
 */
proto.manufacturingapi.MachinePromiseClient.prototype.streamStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/manufacturingapi.Machine/StreamStatus',
      request,
      metadata || {},
      methodDescriptor_Machine_StreamStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manufacturingapi.OutagesRequest,
 *   !proto.manufacturingapi.OutagesResponse>}
 */
const methodDescriptor_Machine_Outages = new grpc.web.MethodDescriptor(
  '/manufacturingapi.Machine/Outages',
  grpc.web.MethodType.UNARY,
  proto.manufacturingapi.OutagesRequest,
  proto.manufacturingapi.OutagesResponse,
  /**
   * @param {!proto.manufacturingapi.OutagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manufacturingapi.OutagesResponse.deserializeBinary
);


/**
 * @param {!proto.manufacturingapi.OutagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manufacturingapi.OutagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manufacturingapi.OutagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manufacturingapi.MachineClient.prototype.outages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manufacturingapi.Machine/Outages',
      request,
      metadata || {},
      methodDescriptor_Machine_Outages,
      callback);
};


/**
 * @param {!proto.manufacturingapi.OutagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manufacturingapi.OutagesResponse>}
 *     Promise that resolves to the response
 */
proto.manufacturingapi.MachinePromiseClient.prototype.outages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manufacturingapi.Machine/Outages',
      request,
      metadata || {},
      methodDescriptor_Machine_Outages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manufacturingapi.OutagesRequest,
 *   !proto.manufacturingapi.OutagesResponse>}
 */
const methodDescriptor_Machine_UpdatedOutages = new grpc.web.MethodDescriptor(
  '/manufacturingapi.Machine/UpdatedOutages',
  grpc.web.MethodType.UNARY,
  proto.manufacturingapi.OutagesRequest,
  proto.manufacturingapi.OutagesResponse,
  /**
   * @param {!proto.manufacturingapi.OutagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manufacturingapi.OutagesResponse.deserializeBinary
);


/**
 * @param {!proto.manufacturingapi.OutagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manufacturingapi.OutagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manufacturingapi.OutagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manufacturingapi.MachineClient.prototype.updatedOutages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manufacturingapi.Machine/UpdatedOutages',
      request,
      metadata || {},
      methodDescriptor_Machine_UpdatedOutages,
      callback);
};


/**
 * @param {!proto.manufacturingapi.OutagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manufacturingapi.OutagesResponse>}
 *     Promise that resolves to the response
 */
proto.manufacturingapi.MachinePromiseClient.prototype.updatedOutages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manufacturingapi.Machine/UpdatedOutages',
      request,
      metadata || {},
      methodDescriptor_Machine_UpdatedOutages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manufacturingapi.MachineListRequest,
 *   !proto.manufacturingapi.MachineListResponse>}
 */
const methodDescriptor_Machine_GetMachines = new grpc.web.MethodDescriptor(
  '/manufacturingapi.Machine/GetMachines',
  grpc.web.MethodType.UNARY,
  proto.manufacturingapi.MachineListRequest,
  proto.manufacturingapi.MachineListResponse,
  /**
   * @param {!proto.manufacturingapi.MachineListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manufacturingapi.MachineListResponse.deserializeBinary
);


/**
 * @param {!proto.manufacturingapi.MachineListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manufacturingapi.MachineListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manufacturingapi.MachineListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manufacturingapi.MachineClient.prototype.getMachines =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manufacturingapi.Machine/GetMachines',
      request,
      metadata || {},
      methodDescriptor_Machine_GetMachines,
      callback);
};


/**
 * @param {!proto.manufacturingapi.MachineListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manufacturingapi.MachineListResponse>}
 *     Promise that resolves to the response
 */
proto.manufacturingapi.MachinePromiseClient.prototype.getMachines =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manufacturingapi.Machine/GetMachines',
      request,
      metadata || {},
      methodDescriptor_Machine_GetMachines);
};


module.exports = proto.manufacturingapi;

