import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import routes from "@/router/routes/index.js";

//avoid redundant navigation
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/:catchAll(.*)",
      component: () => import("@/components/simpleViews/PageDoesNotExistView"),
      meta: {
        is_admin: false,
        requires_auth: true,
      },
    },
    {
      name: "not-found",
      path: "/not-found",
      component: () => import("@/components/simpleViews/PageDoesNotExistView"),
      meta: {
        is_admin: false,
        requires_auth: true,
      },
    },
  ].concat(routes),
});

// verify token
router.beforeEach(async (to, from, next) => {
  let l = router.resolve(to);
  if (l.matched.length <= 0) {
    next({ name: "not found" });
    return;
  }

  const authenticated = store.state.base.authenticated;
  const admin = store.state.base.admin;

  const requiresAuth = to.matched.some((record) => record.meta.requires_auth);
  const isAdminPage = to.matched.some((record) => record.meta.is_admin);
  if (!authenticated || to.name === "login") {
    //unauthenticated aka no jwt token, user can only visit logged in page
    l.name === "login" ? next() : next({ name: "login" }); //ternary to avoid unlimited looping
  } else {
    try {
      await store.dispatch("base/verifyUserGRPC");
    } catch (e) {
      console.error(e);
      return;
    }
    //verify user access
    if (admin) {
      if (isAdminPage) {
        next(); //admin is trying to access the admin page, connect
      } else {
        next({ name: "admin" }); //admin is trying to access non-admin page - do not allow
      }
    } else if (requiresAuth) {
      //connecting to private page with authentication
      next();
    } else {
      //authenticated user trying to access login page
      if (store.state.base.userInfo.permissions.dashboard.page) {
        next("dashboard");
      } else if (store.state.base.userInfo.permissions.printouts.page) {
        next("printouts");
      } else if (
        store.state.base.userInfo.permissions.productionPlanning.page
      ) {
        next("production-planning/recipe");
      } else if (store.state.base.userInfo.outages) {
        next("outages");
      } else if (store.state.base.userInfo.permissions.classification.page) {
        next("classification");
      } else {
        next("/404");
      }
    }
  }
});

export default router;
