import { formatDistance } from "date-fns";
import { formatTimeAgo } from "@vueuse/core";

export const formatMachineStatus = (mr) => {
  let ts = new Date(mr.timestamp.seconds * 1000);
  const now = new Date();
  mr.since = mr.timestamp;
  if (mr.lastGoodProduct !== undefined) {
    let goodProduct = new Date(mr.lastGoodProduct.seconds * 1000);
    let timeSinceLastGoodProduct = new Date(
      now.getTime() - goodProduct.getTime()
    );
    // if good count has not changed in the last 10 minutes
    const maxSinceAlert = 1000 /*s*/ * 60 /*min*/ * 10;
    if (timeSinceLastGoodProduct > maxSinceAlert && mr.status !== "Outage") {
      mr.status = "Timeout";
      mr.reason =
        formatTimeAgo(new Date(goodProduct)) + " since last good product";
    }
  }
  mr.lastSeen = formatDistance(ts, now);
  return mr;
};

export const generateRowVariant = (timestamp) => {
  let ts = new Date(timestamp.seconds * 1000);
  const now = new Date();
  let since = now.getTime() - ts.getTime();
  // mark everything where last status update is greater than an hour
  const maxSince = 1000 /*s*/ * 60 /*min*/ * 60; /*hours*/
  if (since > maxSince) {
    return "secondary";
  }
  return "";
};
