import { grpcApiUrl, rgba2hex, hex2rgba } from "@/helper";
import {
  ListRequest,
  CreateRequest,
  DeleteRequest,
  CloneRequest,
  RenameRequest,
  GetRequest,
  SignRequest,
} from "@/pb/isotronic/hosted/stores/productionplanning/rpc/proto/recipe/RecipeManagmentService_pb.js";
import { RecipeManagementPromiseClient } from "@/pb/isotronic/hosted/stores/productionplanning/rpc/proto/recipe/RecipeManagmentService_grpc_web_pb.js";
import { Color } from "@/pb/isotronic/common/proto/Color_pb.js";
import { Geometry } from "@/pb/isotronic/vialchecker/inspection/checks/base/proto/Geometry_pb.js";
import { Info } from "@/pb/isotronic/vialchecker/inspection/checks/base/proto/Info_pb.js";
import { MeasurementRange } from "@/pb/isotronic/common/inspection/proto/MeasurementRange_pb.js";
import { AggregationType } from "@/pb/isotronic/common/inspection/proto/AggregationType_pb.js";
import {
  PutGeometryCheckRequest,
  DeleteGeometryCheckRequest,
} from "@/pb/isotronic/hosted/stores/productionplanning/rpc/proto/recipe/RecipeEditService_pb.js";
import { RecipeEditPromiseClient } from "@/pb/isotronic/hosted/stores/productionplanning/rpc/proto/recipe/RecipeEditService_grpc_web_pb.js";
import { RecipeDrawingPromiseClient } from "@/pb/isotronic/hosted/stores/productionplanning/rpc/proto/recipe/RecipeDrawingService_grpc_web_pb.js";
import {
  PutDrawingRequest,
  GetDrawingRequest,
} from "@/pb/isotronic/hosted/stores/productionplanning/rpc/proto/recipe/RecipeDrawingService_pb.js";
import { merge } from "lodash";
import options from "@/interceptors";

const list = ({ commit, rootState }) => {
  commit("SET_LOADING_RECIPES", true);
  return new Promise((resolve, reject) => {
    let manageRecipes = new RecipeManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new ListRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    manageRecipes.list(req, {}).then(
      (response) => {
        let recipeList = response.toObject().recipesList;
        recipeList.forEach(function (item) {
          item.nameState = null;
        });
        commit("SET_RECIPES", recipeList);
        commit("SET_LOADING_RECIPES", false);
        resolve();
      },
      (error) => {
        console.error("listing recipe error", error);
        commit("SET_LOADING_RECIPES", false);
        reject(error);
      }
    );
  });
};

const get = ({ commit, rootState }, name) => {
  return new Promise((resolve, reject) => {
    let manageRecipes = new RecipeManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new GetRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setName(name);
    manageRecipes.get(req, {}).then(
      (response) => {
        let recipe = response.toObject();
        recipe.geometryChecksList.forEach(function (item) {
          item.info.color = rgba2hex(item.info.color);
        });
        commit("SET_CURRENT_RECIPE", recipe);
        commit("SET_INITIAL_COLORS", recipe.geometryChecksList);
        resolve();
      },
      (error) => {
        console.error("get recipe error", error);
        reject(error);
      }
    );
  });
};

const create = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    // add new recipePlanning
    let manageRecipes = new RecipeManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new CreateRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setName(data.name);
    manageRecipes.create(req, {}).then(
      (response) => {
        let newRecipe = response.toObject();
        newRecipe.nameState = null;
        commit("ADD_RECIPE", newRecipe);
        resolve(response.toObject());
      },
      (error) => {
        console.error("create recipe error", error);
        reject(error);
      }
    );
  });
};

const clone = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    // add new recipePlanning
    let manageRecipes = new RecipeManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new CloneRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setName(data.name);
    req.setCloneName(data.cloneName);

    manageRecipes.clone(req, {}).then(
      (response) => {
        let recipe = response.toObject();
        recipe.nameState = null;
        commit("ADD_RECIPE", recipe);
        resolve(response.toObject());
      },
      (error) => {
        console.error("clone recipe error", error);
        reject(error);
      }
    );
  });
};

const rename = ({ commit, rootState }, data) => {
  commit("SET_NAME_RECIPE", data);
  return new Promise((resolve, reject) => {
    let manageRecipes = new RecipeManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new RenameRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setOldName(data.oldName);
    req.setNewName(data.newName);
    manageRecipes.rename(req, {}).then(
      () => {
        resolve();
      },
      (error) => {
        console.error("rename error", error);
        reject(error);
      }
    );
  });
};

const deleteRecipe = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    let manageRecipes = new RecipeManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new DeleteRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setName(data.name);
    manageRecipes.delete(req, {}).then(
      () => {
        commit("DELETE_RECIPE", data.name);
        resolve();
      },
      (error) => {
        console.error("delete recipe error", error);
        reject(error);
      }
    );
  });
};

const signRecipe = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    let manageRecipes = new RecipeManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new SignRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setEmail(rootState.base.userInfo.email);
    req.setRecipeName(data.recipe);
    manageRecipes.sign(req, {}).then(
      () => {
        console.log(
          "this was done successfully - now need to commit something"
        );
        commit("SET_SIGN_RECIPE", {
          email: rootState.base.userInfo.email,
        });
        resolve();
      },
      (error) => {
        console.error("sign recipe error", error);
        reject(error);
      }
    );
  });
};

const getRecipeDrawingMetadata = ({ commit, state, rootState }) => {
  return new Promise((resolve, reject) => {
    let uploadDrawing = new RecipeDrawingPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new GetDrawingRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setRecipeName(state.currentRecipe.name);
    uploadDrawing.getFileMetadata(req, {}).then(
      (response) => {
        let drawing = response.toObject();
        commit("SET_DRAWING", {
          name: drawing.fileName,
          last_uploaded: drawing.lastUpdated,
        });
        resolve();
      },
      (error) => {
        console.error("error getting drawing", error);
        reject(error);
      }
    );
  });
};

const setRecipeDrawing = ({ commit, state, rootState }, data) => {
  return new Promise((resolve, reject) => {
    let uploadDrawing = new RecipeDrawingPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new PutDrawingRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setRecipeName(state.currentRecipe.name);
    req.setFileName(data.file.name);
    req.setMimeType(data.file.type);
    req.setContent(data.content);
    uploadDrawing.putFile(req, {}).then(
      (response) => {
        let drawing = response.toObject();
        commit("SET_DRAWING", {
          name: drawing.fileName,
          last_uploaded: drawing.lastUpdated,
        });
        resolve();
      },
      (error) => {
        console.error("error saving drawing", error);
        reject(error);
      }
    );
  });
};

const deleteRecipeDrawing = ({ commit, state, rootState }) => {
  return new Promise((resolve, reject) => {
    let uploadDrawing = new RecipeDrawingPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new GetDrawingRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setRecipeName(state.currentRecipe.name);
    uploadDrawing.delete(req, {}).then(
      () => {
        commit("SET_DRAWING", {
          name: "",
          last_uploaded: "",
        });
        resolve();
      },
      (error) => {
        console.error("error saving drawing", error);
        reject(error);
      }
    );
  });
};
//*********************//
// editRecipe actions  //
//*********************//

let defaultCheckValues = {
  info: {
    color: "#FF0000",
  },
  measurementRange: {
    nominal: 0,
    positiveTolerance: 0,
    negativeTolerance: 0,
    positiveWarningLimit: 0.9,
    negativeWarningLimit: 0.9,
    positiveWarningLimitActive: false,
    negativeWarningLimitActive: false,
  },
  aggregationType: "0",
};

const putCheck = ({ state, commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    if (data.newCheck) {
      data = merge(data, defaultCheckValues);
    } else {
      let checks = state.currentRecipe.geometryChecksList[data.idx];
      data = merge(data, checks);
    }

    let recipeEdit = new RecipeEditPromiseClient(grpcApiUrl(), null, options);
    let req = new PutGeometryCheckRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setRecipeName(data.recipe || state.currentRecipe.name);
    let geometryCheck = new Geometry();
    geometryCheck.setInternalName(data.internalName);

    let info = new Info();
    info.setDescription(data.info.description);
    info.setShortDescription(data.info.shortDescription);
    let color = new Color();
    let rgba = hex2rgba(data.info.color);
    color.setR(rgba.r);
    color.setG(rgba.g);
    color.setB(rgba.b);
    color.setA(rgba.a);
    info.setColor(color);
    geometryCheck.setInfo(info);

    // let aggregationType = AggregationType.MINVALUE
    switch (data.aggregationType) {
      case 0:
        geometryCheck.setAggregationType(AggregationType.MINVALUE);
        break;
      case 1:
        geometryCheck.setAggregationType(AggregationType.MAXVALUE);
        break;
      case 2:
        geometryCheck.setAggregationType(AggregationType.MEANVALUE);
        break;
      case 3:
        geometryCheck.setAggregationType(AggregationType.MAXDEVIATINGVALUE);
        break;
    }

    let measurementRange = new MeasurementRange();
    measurementRange.setNominal(data.measurementRange.nominal);
    measurementRange.setPositiveTolerance(
      data.measurementRange.positiveTolerance
    );
    measurementRange.setNegativeTolerance(
      data.measurementRange.negativeTolerance
    );
    measurementRange.setPositiveWarningLimit(
      data.measurementRange.positiveWarningLimit
    );
    measurementRange.setNegativeWarningLimit(
      data.measurementRange.negativeWarningLimit
    );
    measurementRange.setNegativeWarningLimitActive(
      data.measurementRange.negativeWarningLimitActive
    );
    measurementRange.setPositiveWarningLimitActive(
      data.measurementRange.positiveWarningLimitActive
    );
    geometryCheck.setMeasurementRange(measurementRange);

    data.additionalConfigurationByIdMap.forEach((parameter) => {
      let configuration = new Geometry.AdditionalConfiguration();
      configuration.setDescription(parameter[1].description);
      configuration.setValue(parameter[1].value);
      geometryCheck
        .getAdditionalConfigurationByIdMap()
        .set(parameter[1].description, configuration);
    });

    req.setGeometryCheck(geometryCheck);
    recipeEdit.put(req, {}).then(
      (response) => {
        if (data.newCheck) {
          let recipe = response.toObject();

          recipe.geometryChecksList.forEach((check) => {
            check.info.color = rgba2hex(check.info.color);
          });
          commit("UPDATE_RECIPE", recipe);
        }
        resolve();
      },
      (error) => {
        console.error("put check error", error);
        reject(error);
      }
    );
  });
};

const setCheckColor = ({ commit, dispatch, state }, data) => {
  const idx = state.currentRecipe.geometryChecksList.findIndex(
    (elem) => elem.internalName === data.internalName
  );
  commit("SET_CHECK_COLOR", {
    color: data.color,
    idx: idx,
    internalName: data.internalName,
  });
  dispatch("putCheck", { idx: idx });
};

const setCheckValue = ({ commit, dispatch, state }, data) => {
  const idx = state.currentRecipe.geometryChecksList.findIndex(
    (elem) => elem.internalName === data.internalName
  );
  commit("SET_CHECK_VALUE", {
    newValue: data.value,
    idx: idx,
    type: data.type,
  });
  dispatch("putCheck", { idx: idx });
};

const setCheckWarningLimit = ({ commit, dispatch, state }, data) => {
  const idx = state.currentRecipe.geometryChecksList.findIndex(
    (elem) => elem.internalName === data.internalName
  );
  commit("SET_CHECK_WARNING_LIMIT", {
    newValue: data,
    type: data.type,
    idx: idx,
  });
  dispatch("putCheck", { idx: idx });
};

const setCheckAggregation = ({ commit, dispatch, state }, data) => {
  const idx = state.currentRecipe.geometryChecksList.findIndex(
    (elem) => elem.internalName === data.internalName
  );
  commit("SET_CHECK_AGGREGATION", { aggregation: data.aggregation, idx: idx });
  dispatch("putCheck", { idx: idx });
};

const deleteCheck = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    let recipeEdit = new RecipeEditPromiseClient(grpcApiUrl(), null, options);
    let req = new DeleteGeometryCheckRequest();
    req.setCustomer(rootState.base.userInfo.companyUuid);
    req.setGeometryCheckInternalName(data.internalName);
    req.setRecipeName(data.recipe);
    recipeEdit.delete(req, {}).then(
      () => {
        commit("DELETE_CHECK_COLOR", data.internalName);
        commit("DELETE_CHECK", data.internalName);
        resolve();
      },
      (error) => {
        console.error("delete check error", error);
        reject(error);
      }
    );
  });
};

export default {
  list,
  create,
  clone,
  rename,
  deleteRecipe,
  deleteCheck,
  get,
  putCheck,
  setCheckColor,
  signRecipe,
  setCheckValue,
  setCheckWarningLimit,
  setCheckAggregation,
  setRecipeDrawing,
  getRecipeDrawingMetadata,
  deleteRecipeDrawing,
};
