import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import base from "@/store/modules/base";
import productionStatistics from "@/store/modules/dashboard/productionStatistics";
import tracking from "@/store/modules/tracking";
import outages from "@/store/modules/outages";
import recipe from "@/store/modules/productionPlanning/recipe";
import jobPlanning from "@/store/modules/productionPlanning/jobPlanning";
import cosmetic from "@/store/modules/cosmetic";

const vuexStorage = new VuexPersist({
  key: "portal",
  storage: localStorage,
});

export default createStore({
  modules: {
    base: base,
    productionStatistics: productionStatistics,
    tracking: tracking,
    outages: outages,
    recipe: recipe,
    jobPlanning: jobPlanning,
    cosmetic: cosmetic,
  },
  plugins: [vuexStorage.plugin],
  strict: true,
});
