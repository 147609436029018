const getDefaultState = () => {
  return {
    recipes: [],
    currentRecipe: null,
    loadingRecipes: false,
    timeFilter: {
      start: null,
      end: null,
    },
    recipeFilter: {
      filterValue: "",
    },
    currentDrawing: {
      name: "",
      last_uploaded: "",
    },
    sortDirection: null,
    sortBy: null,
    colors: [
      { name: "blue", color: "#0000FF", used: [] },
      { name: "dodgerblue", color: "#1E90FF", used: [] },
      { name: "mediumslateblue", color: "#7B68EE", used: [] },
      { name: "purple", color: "#9900CC", used: [] },
      { name: "pink", color: "#FF0099", used: [] },
      { name: "lightcoral", color: "#F08080", used: [] },
      { name: "red", color: "#FF0000", used: [] },
      { name: "maroon", color: "#800000", used: [] },
      { name: "tan", color: "#D2B48C", used: [] },
      { name: "darkgrey", color: "#CCCCCC", used: [] },
      { name: "teal", color: "#00695C", used: [] },
      { name: "olive", color: "#808000", used: [] },
      { name: "green", color: "#00CC00", used: [] },
      { name: "springgreen", color: "#00FF7F", used: [] },
      { name: "yellow", color: "#FFFF00", used: [] },
    ],
    addCheck: {
      vialType: null,
      camera: null,
      check: null,
      referenceCheck: null,
      currentPage: null,
    },
  };
};

module.exports = {
  getDefaultState,
};
