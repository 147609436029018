export function grpcApiUrl() {
  return baseUrl();
}

export function restApiUrl() {
  return "https://api.isotronic.cloud/defectdb2json";
}

export function baseUrl() {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:15880";
  }

  let url = window.location.protocol + "//";
  url += window.location.hostname;
  if (window.location.port) {
    url += ":" + window.location.port;
  }
  return url;
}

export function hex2rgba(hex, alpha = 1) {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return {
    r: r,
    g: g,
    b: b,
    a: alpha,
  };
}

export function rgba2hex(rgba) {
  //ignoring a for now
  return (
    "#" +
    ((1 << 24) + (rgba.r << 16) + (rgba.g << 8) + rgba.b)
      .toString(16)
      .slice(1)
      .toUpperCase()
  );
}

export function mod(n, m) {
  return ((n % m) + m) % m;
}

// https://gist.github.com/LeverOne/1308368
export function uuid(a) {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
}

export function isUUID(uuid) {
  let regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(uuid);
}

export function isUUIDWithoutDashes(uuid) {
  let regex = /^[0-9a-f]{32}$/i;
  return regex.test(uuid);
}
export function prettyTime(secs) {
  let sec_num = parseInt(secs, 10);
  let days = Math.floor(sec_num / 86400);
  let hours = Math.floor(sec_num / 3600) % 24;
  let minutes = Math.floor(sec_num / 60) % 60;
  let seconds = sec_num % 60;
  if (days === 0) {
    return hours + "h " + minutes + "m " + seconds + "s";
  }
  return days + "d " + hours + "h " + minutes + "m " + seconds + "s";
}
