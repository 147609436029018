// source: hosted/central/gatekeeper/manufacturingapi/rpc/proto/AnalyticsService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.manufacturingapi.CosmeticCheckBin', null, global);
goog.exportSymbol('proto.manufacturingapi.GeometricCheckBin', null, global);
goog.exportSymbol('proto.manufacturingapi.GetAllJobsOnMachineRequest', null, global);
goog.exportSymbol('proto.manufacturingapi.GetAllJobsOnMachineResponse', null, global);
goog.exportSymbol('proto.manufacturingapi.JobMeta', null, global);
goog.exportSymbol('proto.manufacturingapi.ProductionHistoryRequest', null, global);
goog.exportSymbol('proto.manufacturingapi.ProductionHistoryResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manufacturingapi.GetAllJobsOnMachineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.GetAllJobsOnMachineRequest.displayName = 'proto.manufacturingapi.GetAllJobsOnMachineRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.GetAllJobsOnMachineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manufacturingapi.GetAllJobsOnMachineResponse.repeatedFields_, null);
};
goog.inherits(proto.manufacturingapi.GetAllJobsOnMachineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.GetAllJobsOnMachineResponse.displayName = 'proto.manufacturingapi.GetAllJobsOnMachineResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.JobMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manufacturingapi.JobMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.JobMeta.displayName = 'proto.manufacturingapi.JobMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.ProductionHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manufacturingapi.ProductionHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.ProductionHistoryRequest.displayName = 'proto.manufacturingapi.ProductionHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.ProductionHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manufacturingapi.ProductionHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.manufacturingapi.ProductionHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.ProductionHistoryResponse.displayName = 'proto.manufacturingapi.ProductionHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.GeometricCheckBin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manufacturingapi.GeometricCheckBin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.GeometricCheckBin.displayName = 'proto.manufacturingapi.GeometricCheckBin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manufacturingapi.CosmeticCheckBin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manufacturingapi.CosmeticCheckBin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manufacturingapi.CosmeticCheckBin.displayName = 'proto.manufacturingapi.CosmeticCheckBin';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.GetAllJobsOnMachineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.GetAllJobsOnMachineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    machineName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    current: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.GetAllJobsOnMachineRequest}
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.GetAllJobsOnMachineRequest;
  return proto.manufacturingapi.GetAllJobsOnMachineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.GetAllJobsOnMachineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.GetAllJobsOnMachineRequest}
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.GetAllJobsOnMachineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.GetAllJobsOnMachineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMachineName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrent();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string customer = 1;
 * @return {string}
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.GetAllJobsOnMachineRequest} returns this
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string machine_name = 2;
 * @return {string}
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.prototype.getMachineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.GetAllJobsOnMachineRequest} returns this
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.prototype.setMachineName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool current = 3;
 * @return {boolean}
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.prototype.getCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.manufacturingapi.GetAllJobsOnMachineRequest} returns this
 */
proto.manufacturingapi.GetAllJobsOnMachineRequest.prototype.setCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manufacturingapi.GetAllJobsOnMachineResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.GetAllJobsOnMachineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.GetAllJobsOnMachineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.GetAllJobsOnMachineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.GetAllJobsOnMachineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobsList: jspb.Message.toObjectList(msg.getJobsList(),
    proto.manufacturingapi.JobMeta.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.GetAllJobsOnMachineResponse}
 */
proto.manufacturingapi.GetAllJobsOnMachineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.GetAllJobsOnMachineResponse;
  return proto.manufacturingapi.GetAllJobsOnMachineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.GetAllJobsOnMachineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.GetAllJobsOnMachineResponse}
 */
proto.manufacturingapi.GetAllJobsOnMachineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manufacturingapi.JobMeta;
      reader.readMessage(value,proto.manufacturingapi.JobMeta.deserializeBinaryFromReader);
      msg.addJobs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.GetAllJobsOnMachineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.GetAllJobsOnMachineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.GetAllJobsOnMachineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.GetAllJobsOnMachineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manufacturingapi.JobMeta.serializeBinaryToWriter
    );
  }
};


/**
 * repeated JobMeta jobs = 1;
 * @return {!Array<!proto.manufacturingapi.JobMeta>}
 */
proto.manufacturingapi.GetAllJobsOnMachineResponse.prototype.getJobsList = function() {
  return /** @type{!Array<!proto.manufacturingapi.JobMeta>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manufacturingapi.JobMeta, 1));
};


/**
 * @param {!Array<!proto.manufacturingapi.JobMeta>} value
 * @return {!proto.manufacturingapi.GetAllJobsOnMachineResponse} returns this
*/
proto.manufacturingapi.GetAllJobsOnMachineResponse.prototype.setJobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manufacturingapi.JobMeta=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manufacturingapi.JobMeta}
 */
proto.manufacturingapi.GetAllJobsOnMachineResponse.prototype.addJobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manufacturingapi.JobMeta, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manufacturingapi.GetAllJobsOnMachineResponse} returns this
 */
proto.manufacturingapi.GetAllJobsOnMachineResponse.prototype.clearJobsList = function() {
  return this.setJobsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.JobMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.JobMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.JobMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.JobMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    begin: (f = msg.getBegin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.JobMeta}
 */
proto.manufacturingapi.JobMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.JobMeta;
  return proto.manufacturingapi.JobMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.JobMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.JobMeta}
 */
proto.manufacturingapi.JobMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobName(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBegin(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.JobMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.JobMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.JobMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.JobMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBegin();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string job_name = 1;
 * @return {string}
 */
proto.manufacturingapi.JobMeta.prototype.getJobName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.JobMeta} returns this
 */
proto.manufacturingapi.JobMeta.prototype.setJobName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp begin = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.manufacturingapi.JobMeta.prototype.getBegin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.manufacturingapi.JobMeta} returns this
*/
proto.manufacturingapi.JobMeta.prototype.setBegin = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manufacturingapi.JobMeta} returns this
 */
proto.manufacturingapi.JobMeta.prototype.clearBegin = function() {
  return this.setBegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manufacturingapi.JobMeta.prototype.hasBegin = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.manufacturingapi.JobMeta.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.manufacturingapi.JobMeta} returns this
*/
proto.manufacturingapi.JobMeta.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manufacturingapi.JobMeta} returns this
 */
proto.manufacturingapi.JobMeta.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manufacturingapi.JobMeta.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.ProductionHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.ProductionHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.ProductionHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    machineName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    begin: (f = msg.getBegin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timeInterval: jspb.Message.getFieldWithDefault(msg, 5, 0),
    job: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.ProductionHistoryRequest}
 */
proto.manufacturingapi.ProductionHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.ProductionHistoryRequest;
  return proto.manufacturingapi.ProductionHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.ProductionHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.ProductionHistoryRequest}
 */
proto.manufacturingapi.ProductionHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineName(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBegin(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeInterval(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setJob(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.ProductionHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.ProductionHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.ProductionHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMachineName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBegin();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimeInterval();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getJob();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string customer = 1;
 * @return {string}
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.ProductionHistoryRequest} returns this
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string machine_name = 2;
 * @return {string}
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.getMachineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.ProductionHistoryRequest} returns this
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.setMachineName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp begin = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.getBegin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.manufacturingapi.ProductionHistoryRequest} returns this
*/
proto.manufacturingapi.ProductionHistoryRequest.prototype.setBegin = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manufacturingapi.ProductionHistoryRequest} returns this
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.clearBegin = function() {
  return this.setBegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.hasBegin = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.manufacturingapi.ProductionHistoryRequest} returns this
*/
proto.manufacturingapi.ProductionHistoryRequest.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manufacturingapi.ProductionHistoryRequest} returns this
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 time_interval = 5;
 * @return {number}
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.getTimeInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.ProductionHistoryRequest} returns this
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.setTimeInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string job = 6;
 * @return {string}
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.getJob = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.ProductionHistoryRequest} returns this
 */
proto.manufacturingapi.ProductionHistoryRequest.prototype.setJob = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manufacturingapi.ProductionHistoryResponse.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.ProductionHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.ProductionHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.ProductionHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    begin: (f = msg.getBegin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    productionCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rejectionCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    geometricChecksList: jspb.Message.toObjectList(msg.getGeometricChecksList(),
    proto.manufacturingapi.GeometricCheckBin.toObject, includeInstance),
    jobName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cosmeticChecksList: jspb.Message.toObjectList(msg.getCosmeticChecksList(),
    proto.manufacturingapi.CosmeticCheckBin.toObject, includeInstance),
    isEndOfIteration: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.ProductionHistoryResponse}
 */
proto.manufacturingapi.ProductionHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.ProductionHistoryResponse;
  return proto.manufacturingapi.ProductionHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.ProductionHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.ProductionHistoryResponse}
 */
proto.manufacturingapi.ProductionHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBegin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductionCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRejectionCount(value);
      break;
    case 4:
      var value = new proto.manufacturingapi.GeometricCheckBin;
      reader.readMessage(value,proto.manufacturingapi.GeometricCheckBin.deserializeBinaryFromReader);
      msg.addGeometricChecks(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobName(value);
      break;
    case 6:
      var value = new proto.manufacturingapi.CosmeticCheckBin;
      reader.readMessage(value,proto.manufacturingapi.CosmeticCheckBin.deserializeBinaryFromReader);
      msg.addCosmeticChecks(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEndOfIteration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.ProductionHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.ProductionHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.ProductionHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBegin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProductionCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRejectionCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGeometricChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.manufacturingapi.GeometricCheckBin.serializeBinaryToWriter
    );
  }
  f = message.getJobName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCosmeticChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.manufacturingapi.CosmeticCheckBin.serializeBinaryToWriter
    );
  }
  f = message.getIsEndOfIteration();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp begin = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.getBegin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.manufacturingapi.ProductionHistoryResponse} returns this
*/
proto.manufacturingapi.ProductionHistoryResponse.prototype.setBegin = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manufacturingapi.ProductionHistoryResponse} returns this
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.clearBegin = function() {
  return this.setBegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.hasBegin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 production_count = 2;
 * @return {number}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.getProductionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.ProductionHistoryResponse} returns this
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.setProductionCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 rejection_count = 3;
 * @return {number}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.getRejectionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.ProductionHistoryResponse} returns this
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.setRejectionCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated GeometricCheckBin geometric_checks = 4;
 * @return {!Array<!proto.manufacturingapi.GeometricCheckBin>}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.getGeometricChecksList = function() {
  return /** @type{!Array<!proto.manufacturingapi.GeometricCheckBin>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manufacturingapi.GeometricCheckBin, 4));
};


/**
 * @param {!Array<!proto.manufacturingapi.GeometricCheckBin>} value
 * @return {!proto.manufacturingapi.ProductionHistoryResponse} returns this
*/
proto.manufacturingapi.ProductionHistoryResponse.prototype.setGeometricChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.manufacturingapi.GeometricCheckBin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manufacturingapi.GeometricCheckBin}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.addGeometricChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.manufacturingapi.GeometricCheckBin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manufacturingapi.ProductionHistoryResponse} returns this
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.clearGeometricChecksList = function() {
  return this.setGeometricChecksList([]);
};


/**
 * optional string job_name = 5;
 * @return {string}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.getJobName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.ProductionHistoryResponse} returns this
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.setJobName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated CosmeticCheckBin cosmetic_checks = 6;
 * @return {!Array<!proto.manufacturingapi.CosmeticCheckBin>}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.getCosmeticChecksList = function() {
  return /** @type{!Array<!proto.manufacturingapi.CosmeticCheckBin>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manufacturingapi.CosmeticCheckBin, 6));
};


/**
 * @param {!Array<!proto.manufacturingapi.CosmeticCheckBin>} value
 * @return {!proto.manufacturingapi.ProductionHistoryResponse} returns this
*/
proto.manufacturingapi.ProductionHistoryResponse.prototype.setCosmeticChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.manufacturingapi.CosmeticCheckBin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manufacturingapi.CosmeticCheckBin}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.addCosmeticChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.manufacturingapi.CosmeticCheckBin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manufacturingapi.ProductionHistoryResponse} returns this
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.clearCosmeticChecksList = function() {
  return this.setCosmeticChecksList([]);
};


/**
 * optional bool is_end_of_iteration = 7;
 * @return {boolean}
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.getIsEndOfIteration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.manufacturingapi.ProductionHistoryResponse} returns this
 */
proto.manufacturingapi.ProductionHistoryResponse.prototype.setIsEndOfIteration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.GeometricCheckBin.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.GeometricCheckBin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.GeometricCheckBin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.GeometricCheckBin.toObject = function(includeInstance, msg) {
  var f, obj = {
    badAboveNominal: jspb.Message.getFieldWithDefault(msg, 1, 0),
    badBelowNominal: jspb.Message.getFieldWithDefault(msg, 2, 0),
    internalName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    longName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.GeometricCheckBin}
 */
proto.manufacturingapi.GeometricCheckBin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.GeometricCheckBin;
  return proto.manufacturingapi.GeometricCheckBin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.GeometricCheckBin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.GeometricCheckBin}
 */
proto.manufacturingapi.GeometricCheckBin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBadAboveNominal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBadBelowNominal(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.GeometricCheckBin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.GeometricCheckBin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.GeometricCheckBin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.GeometricCheckBin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadAboveNominal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBadBelowNominal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLongName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 bad_above_nominal = 1;
 * @return {number}
 */
proto.manufacturingapi.GeometricCheckBin.prototype.getBadAboveNominal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.GeometricCheckBin} returns this
 */
proto.manufacturingapi.GeometricCheckBin.prototype.setBadAboveNominal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 bad_below_nominal = 2;
 * @return {number}
 */
proto.manufacturingapi.GeometricCheckBin.prototype.getBadBelowNominal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.GeometricCheckBin} returns this
 */
proto.manufacturingapi.GeometricCheckBin.prototype.setBadBelowNominal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string internal_name = 3;
 * @return {string}
 */
proto.manufacturingapi.GeometricCheckBin.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.GeometricCheckBin} returns this
 */
proto.manufacturingapi.GeometricCheckBin.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string short_name = 4;
 * @return {string}
 */
proto.manufacturingapi.GeometricCheckBin.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.GeometricCheckBin} returns this
 */
proto.manufacturingapi.GeometricCheckBin.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string long_name = 5;
 * @return {string}
 */
proto.manufacturingapi.GeometricCheckBin.prototype.getLongName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.GeometricCheckBin} returns this
 */
proto.manufacturingapi.GeometricCheckBin.prototype.setLongName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manufacturingapi.CosmeticCheckBin.prototype.toObject = function(opt_includeInstance) {
  return proto.manufacturingapi.CosmeticCheckBin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manufacturingapi.CosmeticCheckBin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.CosmeticCheckBin.toObject = function(includeInstance, msg) {
  var f, obj = {
    badCheckCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    internalName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    longName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manufacturingapi.CosmeticCheckBin}
 */
proto.manufacturingapi.CosmeticCheckBin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manufacturingapi.CosmeticCheckBin;
  return proto.manufacturingapi.CosmeticCheckBin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manufacturingapi.CosmeticCheckBin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manufacturingapi.CosmeticCheckBin}
 */
proto.manufacturingapi.CosmeticCheckBin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBadCheckCount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manufacturingapi.CosmeticCheckBin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manufacturingapi.CosmeticCheckBin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manufacturingapi.CosmeticCheckBin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manufacturingapi.CosmeticCheckBin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadCheckCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLongName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 bad_check_count = 1;
 * @return {number}
 */
proto.manufacturingapi.CosmeticCheckBin.prototype.getBadCheckCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manufacturingapi.CosmeticCheckBin} returns this
 */
proto.manufacturingapi.CosmeticCheckBin.prototype.setBadCheckCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string internal_name = 2;
 * @return {string}
 */
proto.manufacturingapi.CosmeticCheckBin.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.CosmeticCheckBin} returns this
 */
proto.manufacturingapi.CosmeticCheckBin.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 3;
 * @return {string}
 */
proto.manufacturingapi.CosmeticCheckBin.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.CosmeticCheckBin} returns this
 */
proto.manufacturingapi.CosmeticCheckBin.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string long_name = 4;
 * @return {string}
 */
proto.manufacturingapi.CosmeticCheckBin.prototype.getLongName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manufacturingapi.CosmeticCheckBin} returns this
 */
proto.manufacturingapi.CosmeticCheckBin.prototype.setLongName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.manufacturingapi);
