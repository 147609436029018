const SET_PRODUCTION_GRAPH_DATA = (state, zeroedData) => {
  state.production.categoryDates = [...zeroedData.categoryDates];
  state.production.valueDataProduced = [...zeroedData.valueDataProduced];
  state.production.valueDataRejected = [...zeroedData.valueDataRejected];
  state.production.valuePercentRejected = [...zeroedData.valuePercentRejected];
  state.production.jobs = [...zeroedData.jobs];
  state.production.geometricCheckBins = [
    JSON.parse(JSON.stringify(zeroedData.geometricCheckBins)),
  ];
  state.production.cosmeticCheckBins = [
    JSON.parse(JSON.stringify(zeroedData.cosmeticCheckBins)),
  ];
};

const SET_SHIFT_PRODUCTION_DATA = (state, zeroedData) => {
  state.shiftData.categoryDates = [...zeroedData.categoryDates];
  state.shiftData.valueDataProduced = [...zeroedData.valueDataProduced];
  state.shiftData.valueDataRejected = [...zeroedData.valueDataRejected];
};

const SET_DEFECT_MAP = (state, defects) => {
  state.production.defectMap = defects;
};

const SET_PRODUCTION_LOADING = (state, data) => {
  state.production.loading = data;
};

const SET_SELECTED_MACHINE = (state, machine) => {
  state.selectedMachine = machine;
};

const SET_MACHINES = (state, machines) => {
  state.machines = machines;
};

const SET_MANUAL_ZOOM = (state, value) => {
  state.manualZoom = value;
};

const SET_ZOOM_DATA = (state, zoomData) => {
  const sum = state.production.valueDataProduced
    .slice(zoomData.startIndex, zoomData.endIndex + 1)
    .reduce((a, b) => a + b, 0);
  const rejectedSum = state.production.valueDataRejected
    .slice(zoomData.startIndex, zoomData.endIndex + 1)
    .reduce((a, b) => a + b, 0);

  state.zoom.startIndex = zoomData.startIndex;
  state.zoom.endIndex = zoomData.endIndex;
  state.zoom.sum = sum;
  state.zoom.rejectedSum = rejectedSum;

  if (state.zoom.sum === 0 && state.zoom.rejectedSum === 0) {
    state.zoom.percentRejected = 0;
  } else {
    state.zoom.percentRejected =
      (state.zoom.rejectedSum / (state.zoom.sum + state.zoom.rejectedSum)) *
      100;
  }
};

const SET_SHOW_REJECT_RATE = (state) => {
  state.trendCard.showRejectRate = !state.trendCard.showRejectRate;
};

const SET_SHOW_GEOMETRIC = (state) => {
  state.trendCard.showGeometric = !state.trendCard.showGeometric;
};

const SET_SHOW_COSMETIC = (state) => {
  state.trendCard.showCosmetic = !state.trendCard.showCosmetic;
};

const SET_MACHINE_STATUS = (state, status) => {
  state.machineStatus = status;
};

const SET_COMPUTED_SHIFT_DATA = (state, shifts) => {
  state.shiftData.computedShiftData = shifts;
};

const SET_JOB_LIST = (state, jobs) => {
  state.jobList.jobs = jobs;
};

const SET_SELECTED_JOB_INDEX = (state, jobIndex) => {
  state.jobList.selectedJob = jobIndex;
};

const SET_SELECTED_SHIFT = (state, shift) => {
  state.shiftData.selectedShift = shift;
};

const SET_PAGE_FILTER_DROPDOWN = (state, filterData) => {
  state.pageFilterDropdown.start = filterData.start;
  state.pageFilterDropdown.end = filterData.end;
  state.pageFilterDropdown.text = filterData.text;
};

const SET_LAST_UPDATE = (state) => {
  state.jobList.lastUpdate = new Date();
};

export default {
  SET_PRODUCTION_GRAPH_DATA,
  SET_SHIFT_PRODUCTION_DATA,
  SET_PRODUCTION_LOADING,
  SET_SELECTED_MACHINE,
  SET_MACHINES,
  SET_DEFECT_MAP,
  SET_ZOOM_DATA,
  SET_SHOW_REJECT_RATE,
  SET_MACHINE_STATUS,
  SET_SHOW_GEOMETRIC,
  SET_SHOW_COSMETIC,
  SET_COMPUTED_SHIFT_DATA,
  SET_JOB_LIST,
  SET_SELECTED_JOB_INDEX,
  SET_PAGE_FILTER_DROPDOWN,
  SET_LAST_UPDATE,
  SET_SELECTED_SHIFT,
  SET_MANUAL_ZOOM,
};
