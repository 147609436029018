import _ from "lodash";

const SET_OUTAGES = (state, outages) => {
  state.outages = outages;
};

const SET_OUTAGE_LIST = (state, outages) => {
  state.outageList = outages;
};

const SET_MACHINE_BINS = (state, machines) => {
  state.machineBins = _.unionBy(machines, state.machineBins, 0);
};

const SET_LOADING = (state, data) => {
  state.loading = data;
};

const UPDATE_FILTER_DATA = (state, data) => {
  state.filters.time.begin = data.startDate || state.filters.time.begin;
  state.filters.time.end = data.endDate || state.filters.time.end;
  state.filters.machines = data.machines || state.filters.machines;
  state.filters.shifts = data.shifts || state.filters.shifts;
};

export default {
  SET_OUTAGES,
  SET_OUTAGE_LIST,
  SET_MACHINE_BINS,
  UPDATE_FILTER_DATA,
  SET_LOADING,
};
