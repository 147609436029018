const getDefaultState = () => {
  return {
    jobs: [],
    machines: null,
    timeFilter: {
      start: null,
      end: null,
    },
  };
};

module.exports = {
  getDefaultState,
};
