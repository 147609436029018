// source: hosted/stores/productionplanning/rpc/proto/job/Job.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var hosted_stores_productionplanning_rpc_proto_recipe_Recipe_pb = require('../../../../../../hosted/stores/productionplanning/rpc/proto/recipe/Recipe_pb.js');
goog.object.extend(proto, hosted_stores_productionplanning_rpc_proto_recipe_Recipe_pb);
goog.exportSymbol('proto.job.Job', null, global);
goog.exportSymbol('proto.job.Job.MetaInfo', null, global);
goog.exportSymbol('proto.job.Job.Milestones', null, global);
goog.exportSymbol('proto.job.Job.Planning', null, global);
goog.exportSymbol('proto.job.Job.ProductionSetup', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job.Job.displayName = 'proto.job.Job';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job.Job.MetaInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job.Job.MetaInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job.Job.MetaInfo.displayName = 'proto.job.Job.MetaInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job.Job.Planning = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job.Job.Planning, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job.Job.Planning.displayName = 'proto.job.Job.Planning';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job.Job.ProductionSetup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job.Job.ProductionSetup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job.Job.ProductionSetup.displayName = 'proto.job.Job.ProductionSetup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job.Job.Milestones = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job.Job.Milestones, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job.Job.Milestones.displayName = 'proto.job.Job.Milestones';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.job.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    recipeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nominalQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    metaInfo: (f = msg.getMetaInfo()) && proto.job.Job.MetaInfo.toObject(includeInstance, f),
    planning: (f = msg.getPlanning()) && proto.job.Job.Planning.toObject(includeInstance, f),
    productionSetup: (f = msg.getProductionSetup()) && proto.job.Job.ProductionSetup.toObject(includeInstance, f),
    milestones: (f = msg.getMilestones()) && proto.job.Job.Milestones.toObject(includeInstance, f),
    recipe: (f = msg.getRecipe()) && hosted_stores_productionplanning_rpc_proto_recipe_Recipe_pb.Recipe.toObject(includeInstance, f),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job.Job}
 */
proto.job.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job.Job;
  return proto.job.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job.Job}
 */
proto.job.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipeName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNominalQuantity(value);
      break;
    case 4:
      var value = new proto.job.Job.MetaInfo;
      reader.readMessage(value,proto.job.Job.MetaInfo.deserializeBinaryFromReader);
      msg.setMetaInfo(value);
      break;
    case 5:
      var value = new proto.job.Job.Planning;
      reader.readMessage(value,proto.job.Job.Planning.deserializeBinaryFromReader);
      msg.setPlanning(value);
      break;
    case 6:
      var value = new proto.job.Job.ProductionSetup;
      reader.readMessage(value,proto.job.Job.ProductionSetup.deserializeBinaryFromReader);
      msg.setProductionSetup(value);
      break;
    case 7:
      var value = new proto.job.Job.Milestones;
      reader.readMessage(value,proto.job.Job.Milestones.deserializeBinaryFromReader);
      msg.setMilestones(value);
      break;
    case 8:
      var value = new hosted_stores_productionplanning_rpc_proto_recipe_Recipe_pb.Recipe;
      reader.readMessage(value,hosted_stores_productionplanning_rpc_proto_recipe_Recipe_pb.Recipe.deserializeBinaryFromReader);
      msg.setRecipe(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRecipeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNominalQuantity();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMetaInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.job.Job.MetaInfo.serializeBinaryToWriter
    );
  }
  f = message.getPlanning();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.job.Job.Planning.serializeBinaryToWriter
    );
  }
  f = message.getProductionSetup();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.job.Job.ProductionSetup.serializeBinaryToWriter
    );
  }
  f = message.getMilestones();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.job.Job.Milestones.serializeBinaryToWriter
    );
  }
  f = message.getRecipe();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      hosted_stores_productionplanning_rpc_proto_recipe_Recipe_pb.Recipe.serializeBinaryToWriter
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job.Job.MetaInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.job.Job.MetaInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job.Job.MetaInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job.Job.MetaInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    foreignCustomerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    foreignCustomerOrderNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    glassType: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job.Job.MetaInfo}
 */
proto.job.Job.MetaInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job.Job.MetaInfo;
  return proto.job.Job.MetaInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job.Job.MetaInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job.Job.MetaInfo}
 */
proto.job.Job.MetaInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForeignCustomerName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setForeignCustomerOrderNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlassType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job.Job.MetaInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job.Job.MetaInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job.Job.MetaInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job.Job.MetaInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForeignCustomerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getForeignCustomerOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGlassType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string foreign_customer_name = 1;
 * @return {string}
 */
proto.job.Job.MetaInfo.prototype.getForeignCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.job.Job.MetaInfo} returns this
 */
proto.job.Job.MetaInfo.prototype.setForeignCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string foreign_customer_order_number = 2;
 * @return {string}
 */
proto.job.Job.MetaInfo.prototype.getForeignCustomerOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job.Job.MetaInfo} returns this
 */
proto.job.Job.MetaInfo.prototype.setForeignCustomerOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.job.Job.MetaInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.job.Job.MetaInfo} returns this
 */
proto.job.Job.MetaInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string glass_type = 4;
 * @return {string}
 */
proto.job.Job.MetaInfo.prototype.getGlassType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.job.Job.MetaInfo} returns this
 */
proto.job.Job.MetaInfo.prototype.setGlassType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job.Job.Planning.prototype.toObject = function(opt_includeInstance) {
  return proto.job.Job.Planning.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job.Job.Planning} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job.Job.Planning.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    begin: (f = msg.getBegin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job.Job.Planning}
 */
proto.job.Job.Planning.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job.Job.Planning;
  return proto.job.Job.Planning.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job.Job.Planning} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job.Job.Planning}
 */
proto.job.Job.Planning.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineName(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBegin(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job.Job.Planning.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job.Job.Planning.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job.Job.Planning} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job.Job.Planning.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBegin();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string machine_name = 1;
 * @return {string}
 */
proto.job.Job.Planning.prototype.getMachineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.job.Job.Planning} returns this
 */
proto.job.Job.Planning.prototype.setMachineName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp begin = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.job.Job.Planning.prototype.getBegin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.job.Job.Planning} returns this
*/
proto.job.Job.Planning.prototype.setBegin = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job.Job.Planning} returns this
 */
proto.job.Job.Planning.prototype.clearBegin = function() {
  return this.setBegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job.Job.Planning.prototype.hasBegin = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.job.Job.Planning.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.job.Job.Planning} returns this
*/
proto.job.Job.Planning.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job.Job.Planning} returns this
 */
proto.job.Job.Planning.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job.Job.Planning.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job.Job.ProductionSetup.prototype.toObject = function(opt_includeInstance) {
  return proto.job.Job.ProductionSetup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job.Job.ProductionSetup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job.Job.ProductionSetup.toObject = function(includeInstance, msg) {
  var f, obj = {
    packCountIncrement: jspb.Message.getFieldWithDefault(msg, 1, 0),
    targetVialsPerHour: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job.Job.ProductionSetup}
 */
proto.job.Job.ProductionSetup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job.Job.ProductionSetup;
  return proto.job.Job.ProductionSetup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job.Job.ProductionSetup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job.Job.ProductionSetup}
 */
proto.job.Job.ProductionSetup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPackCountIncrement(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetVialsPerHour(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job.Job.ProductionSetup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job.Job.ProductionSetup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job.Job.ProductionSetup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job.Job.ProductionSetup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackCountIncrement();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTargetVialsPerHour();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 pack_count_increment = 1;
 * @return {number}
 */
proto.job.Job.ProductionSetup.prototype.getPackCountIncrement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job.Job.ProductionSetup} returns this
 */
proto.job.Job.ProductionSetup.prototype.setPackCountIncrement = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 target_vials_per_hour = 2;
 * @return {number}
 */
proto.job.Job.ProductionSetup.prototype.getTargetVialsPerHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.job.Job.ProductionSetup} returns this
 */
proto.job.Job.ProductionSetup.prototype.setTargetVialsPerHour = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job.Job.Milestones.prototype.toObject = function(opt_includeInstance) {
  return proto.job.Job.Milestones.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job.Job.Milestones} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job.Job.Milestones.toObject = function(includeInstance, msg) {
  var f, obj = {
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    started: (f = msg.getStarted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completed: (f = msg.getCompleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job.Job.Milestones}
 */
proto.job.Job.Milestones.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job.Job.Milestones;
  return proto.job.Job.Milestones.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job.Job.Milestones} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job.Job.Milestones}
 */
proto.job.Job.Milestones.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarted(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job.Job.Milestones.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job.Job.Milestones.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job.Job.Milestones} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job.Job.Milestones.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStarted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp created = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.job.Job.Milestones.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.job.Job.Milestones} returns this
*/
proto.job.Job.Milestones.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job.Job.Milestones} returns this
 */
proto.job.Job.Milestones.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job.Job.Milestones.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp started = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.job.Job.Milestones.prototype.getStarted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.job.Job.Milestones} returns this
*/
proto.job.Job.Milestones.prototype.setStarted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job.Job.Milestones} returns this
 */
proto.job.Job.Milestones.prototype.clearStarted = function() {
  return this.setStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job.Job.Milestones.prototype.hasStarted = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp completed = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.job.Job.Milestones.prototype.getCompleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.job.Job.Milestones} returns this
*/
proto.job.Job.Milestones.prototype.setCompleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job.Job.Milestones} returns this
 */
proto.job.Job.Milestones.prototype.clearCompleted = function() {
  return this.setCompleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job.Job.Milestones.prototype.hasCompleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.job.Job.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.job.Job} returns this
 */
proto.job.Job.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string recipe_name = 2;
 * @return {string}
 */
proto.job.Job.prototype.getRecipeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job.Job} returns this
 */
proto.job.Job.prototype.setRecipeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 nominal_quantity = 3;
 * @return {number}
 */
proto.job.Job.prototype.getNominalQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.job.Job} returns this
 */
proto.job.Job.prototype.setNominalQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional MetaInfo meta_info = 4;
 * @return {?proto.job.Job.MetaInfo}
 */
proto.job.Job.prototype.getMetaInfo = function() {
  return /** @type{?proto.job.Job.MetaInfo} */ (
    jspb.Message.getWrapperField(this, proto.job.Job.MetaInfo, 4));
};


/**
 * @param {?proto.job.Job.MetaInfo|undefined} value
 * @return {!proto.job.Job} returns this
*/
proto.job.Job.prototype.setMetaInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job.Job} returns this
 */
proto.job.Job.prototype.clearMetaInfo = function() {
  return this.setMetaInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job.Job.prototype.hasMetaInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Planning planning = 5;
 * @return {?proto.job.Job.Planning}
 */
proto.job.Job.prototype.getPlanning = function() {
  return /** @type{?proto.job.Job.Planning} */ (
    jspb.Message.getWrapperField(this, proto.job.Job.Planning, 5));
};


/**
 * @param {?proto.job.Job.Planning|undefined} value
 * @return {!proto.job.Job} returns this
*/
proto.job.Job.prototype.setPlanning = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job.Job} returns this
 */
proto.job.Job.prototype.clearPlanning = function() {
  return this.setPlanning(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job.Job.prototype.hasPlanning = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ProductionSetup production_setup = 6;
 * @return {?proto.job.Job.ProductionSetup}
 */
proto.job.Job.prototype.getProductionSetup = function() {
  return /** @type{?proto.job.Job.ProductionSetup} */ (
    jspb.Message.getWrapperField(this, proto.job.Job.ProductionSetup, 6));
};


/**
 * @param {?proto.job.Job.ProductionSetup|undefined} value
 * @return {!proto.job.Job} returns this
*/
proto.job.Job.prototype.setProductionSetup = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job.Job} returns this
 */
proto.job.Job.prototype.clearProductionSetup = function() {
  return this.setProductionSetup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job.Job.prototype.hasProductionSetup = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Milestones milestones = 7;
 * @return {?proto.job.Job.Milestones}
 */
proto.job.Job.prototype.getMilestones = function() {
  return /** @type{?proto.job.Job.Milestones} */ (
    jspb.Message.getWrapperField(this, proto.job.Job.Milestones, 7));
};


/**
 * @param {?proto.job.Job.Milestones|undefined} value
 * @return {!proto.job.Job} returns this
*/
proto.job.Job.prototype.setMilestones = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job.Job} returns this
 */
proto.job.Job.prototype.clearMilestones = function() {
  return this.setMilestones(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job.Job.prototype.hasMilestones = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional recipe.Recipe recipe = 8;
 * @return {?proto.recipe.Recipe}
 */
proto.job.Job.prototype.getRecipe = function() {
  return /** @type{?proto.recipe.Recipe} */ (
    jspb.Message.getWrapperField(this, hosted_stores_productionplanning_rpc_proto_recipe_Recipe_pb.Recipe, 8));
};


/**
 * @param {?proto.recipe.Recipe|undefined} value
 * @return {!proto.job.Job} returns this
*/
proto.job.Job.prototype.setRecipe = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job.Job} returns this
 */
proto.job.Job.prototype.clearRecipe = function() {
  return this.setRecipe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job.Job.prototype.hasRecipe = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp created = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.job.Job.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.job.Job} returns this
*/
proto.job.Job.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job.Job} returns this
 */
proto.job.Job.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job.Job.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 9) != null;
};


goog.object.extend(exports, proto.job);
