const SET_JOBS = (state, jobs) => {
  state.jobs = jobs;
};

const ADD_JOB = (state, job) => {
  state.jobs.unshift(job);
};

const DELETE_JOB = (state, job) => {
  const idx = state.jobs.findIndex((elem) => elem.name === job);
  if (idx !== -1) {
    state.jobs.splice(idx, 1);
  }
};

const SET_MACHINES = (state, machines) => {
  state.machines = machines;
};

const SET_TIME_FILTER = (state, data) => {
  state.timeFilter.start = data.start;
  state.timeFilter.end = data.end;
};

export default {
  SET_JOBS,
  ADD_JOB,
  DELETE_JOB,
  SET_MACHINES,
  SET_TIME_FILTER,
};
