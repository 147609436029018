const getDefaultState = () => {
  return {
    time: null,
    queryTime: null,
    sort: null,
    querySort: null,
    defectType: 11,
    manualOrAuto: false,
  };
};

module.exports = {
  getDefaultState,
};
