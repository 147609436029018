import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_navbar_nav = _resolveComponent("b-navbar-nav");
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item");
  const _component_b_nav_item_dropdown = _resolveComponent("b-nav-item-dropdown");
  const _component_b_navbar = _resolveComponent("b-navbar");
  return _openBlock(), _createBlock(_component_b_navbar, {
    type: "light",
    variant: "light",
    class: "header mb-5"
  }, {
    default: _withCtx(() => [!_ctx.admin ? (_openBlock(), _createBlock(_component_b_navbar_nav, {
      key: 0
    }, {
      default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
        icon: "bars",
        size: "lg",
        class: "bars-icon",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.SET_MENU_COLLAPSE(!_ctx.collapsed), ["stop"]))
      })]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_b_navbar_nav, {
      class: "ms-auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_nav_item_dropdown, null, {
        "button-content": _withCtx(() => [_createTextVNode(" User ")]),
        default: _withCtx(() => [!_ctx.admin ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
          key: 0,
          onClick: _cache[1] || (_cache[1] = $event => _ctx.$router.push({
            name: 'user settings'
          }))
        }, {
          default: _withCtx(() => [_createTextVNode("Settings")]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_b_dropdown_item, {
          onClick: $options.logout
        }, {
          default: _withCtx(() => [_createTextVNode("Sign Out")]),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}