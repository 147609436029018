import { GetAllJobsOnMachineRequest } from "@/pb/isotronic/hosted/central/gatekeeper/manufacturingapi/rpc/proto/AnalyticsService_pb";
import {
  AnalyticsClient,
  AnalyticsPromiseClient,
} from "@/pb/isotronic/hosted/central/gatekeeper/manufacturingapi/rpc/proto/AnalyticsService_grpc_web_pb";
import { MachinePromiseClient } from "@/pb/isotronic/hosted/central/gatekeeper/manufacturingapi/rpc/proto/MachineService_grpc_web_pb";
import { StatusRequest } from "@/pb/isotronic/hosted/central/gatekeeper/manufacturingapi/rpc/proto/MachineService_pb";
import { grpcApiUrl } from "@/helper";
import { addZeroValues, convertTime, squashDuplicates } from "./helpers";
import {
  prepareRequest,
  handleData,
  finalizeData,
} from "./productionDataHelperFunctions";
import { formatMachineStatus } from "@/store/utils";
import store from "@/store";
import options from "@/interceptors";

//stream variable is set here instead of in store because the stream variable
//cannot be serialized by json, which is required for vuex-persisted state
let machineStatusStream = null;
let productionDataStream = null;
let shiftDataStream = null;

const loadProductionHistory = async (
  { commit, rootState, state },
  timeSpan
) => {
  try {
    let analyticsClient = new AnalyticsClient(grpcApiUrl(), null, null);
    commit("SET_PRODUCTION_LOADING", true);
    let initialLoad = true;

    const {
      productionHistoryRequest,
      definedTimespan,
      timestampBegin,
      timestampEnd,
    } = prepareRequest(rootState, state, timeSpan);

    const defectList = new Map();
    const dataArrays = {
      produced: [],
      rejected: [],
      percentRejected: [],
      dates: [],
      jobs: [],
      geometricCheckBins: [],
      cosmeticCheckBins: [],
    };

    const timeZone = rootState.base.userInfo.timeZone;

    productionDataStream = analyticsClient.getProductionHistory(
      productionHistoryRequest,
      null
    );

    productionDataStream.on("data", (productionHistoryResponse) => {
      if (productionHistoryResponse.toObject().isEndOfIteration) {
        finalizeData(
          commit,
          state,
          defectList,
          dataArrays,
          definedTimespan,
          timestampBegin,
          timestampEnd,
          timeZone,
          timeSpan,
          initialLoad
        );
        initialLoad = false;
      } else {
        handleData(
          productionHistoryResponse.toObject(),
          defectList,
          dataArrays,
          timeZone
        );
      }
    });
  } catch (error) {
    console.error(error);
    commit("SET_PRODUCTION_LOADING", false);
  }
};

const loadDataForShifts = ({ commit, rootState, state }) => {
  try {
    let analyticsClient = new AnalyticsClient(grpcApiUrl(), null, null);
    const { productionHistoryRequest, timestampBegin, timestampEnd } =
      prepareRequest(rootState, state);
    const dataArrays = {
      produced: [],
      rejected: [],
      dates: [],
    };
    shiftDataStream = analyticsClient.getProductionHistory(
      productionHistoryRequest,
      null
    );
    shiftDataStream.on("data", async (productionHistoryResponse) => {
      if (productionHistoryResponse.toObject().isEndOfIteration) {
        let data = [];
        data.valueDataProduced = dataArrays.produced;
        data.valueDataRejected = dataArrays.rejected;
        data.categoryDates = dataArrays.dates;
        let squashedData = await squashDuplicates(data, true);
        let zeroedData = await addZeroValues(
          squashedData,
          store.state.base.userInfo.timeZone,
          timestampBegin,
          timestampEnd,
          true
        );
        commit("SET_SHIFT_PRODUCTION_DATA", zeroedData);
        // Empty the data arrays for the next stream
        dataArrays.produced.length = 0;
        dataArrays.rejected.length = 0;
        dataArrays.dates.length = 0;
      } else {
        let res = productionHistoryResponse.toObject();
        dataArrays.dates.push(
          convertTime(
            res.begin,
            "yyyy-LL-dd HH:mm",
            store.state.base.userInfo.timeZone
          )
        );
        dataArrays.produced.push(res.productionCount);
        dataArrays.rejected.push(res.rejectionCount); //this shows all defects regardless if cosmetic or geometric
      }
    });
  } catch (error) {
    console.error(error);
  }
};

const loadJobList = ({ commit, rootState, state }) => {
  return new Promise((resolve, reject) => {
    let analyticsClient = new AnalyticsPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let jobRequest = new GetAllJobsOnMachineRequest();
    jobRequest.setCustomer(rootState.base.userInfo.companyUuid);
    jobRequest.setMachineName(state.selectedMachine);
    analyticsClient
      .getAllJobsOnMachine(jobRequest, {})
      .then((res) => {
        commit("SET_JOB_LIST", res.toObject().jobsList);
        resolve();
      })
      .catch((err) => {
        console.error(err);
        reject();
      });
  });
};

const loadMachineStatus = ({ commit, rootState, state }) => {
  let machineClient = new MachinePromiseClient(grpcApiUrl(), null, null);
  let machineStatusRequest = new StatusRequest();
  machineStatusRequest.setCustomer(rootState.base.userInfo.companyUuid);
  machineStatusRequest.setMachineName(state.selectedMachine);

  machineStatusStream = machineClient.streamStatus(machineStatusRequest);
  machineStatusStream.on("data", (machineStatusResponse) => {
    let res = machineStatusResponse.toObject();
    formatMachineStatus(res);
    commit("SET_MACHINE_STATUS", res);
  });

  machineStatusStream.on("error", (err) => {
    console.error(err);
  });

  machineStatusStream.on("end", () => {
    console.log("machine status stream ended");
  });
};

const stopMachineStatusStream = () => {
  if (machineStatusStream) {
    machineStatusStream.cancel();
    machineStatusStream = null;
  }
};

const stopProductionDataStream = () => {
  if (productionDataStream) {
    productionDataStream.cancel();
    productionDataStream = null;
  }
};

const stopShiftDataStream = () => {
  if (shiftDataStream) {
    shiftDataStream.cancel();
    shiftDataStream = null;
  }
};

export default {
  loadMachineStatus,
  loadProductionHistory,
  stopMachineStatusStream,
  stopProductionDataStream,
  stopShiftDataStream,
  loadJobList,
  loadDataForShifts,
};
