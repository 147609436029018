import { grpcApiUrl } from "../../../helper";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { MachinePromiseClient } from "@/pb/isotronic/hosted/central/gatekeeper/manufacturingapi/rpc/proto/MachineService_grpc_web_pb.js";
import { OutagesRequest } from "@/pb/isotronic/hosted/central/gatekeeper/manufacturingapi/rpc/proto/MachineService_pb.js";
import options from "@/interceptors";
import { DateTime } from "luxon";

const loadOutages = ({ state, commit, rootState }) => {
  return new Promise((resolve, reject) => {
    commit("SET_LOADING", true);
    let machineClient = new MachinePromiseClient(grpcApiUrl(), null, options);
    let req = new OutagesRequest();
    req.setCompany(rootState.base.userInfo.companyUuid);
    req.setMachinesList(state.filters.machines);
    const timestamp_end = new Timestamp();
    state.filters.time.end === "now"
      ? timestamp_end.fromDate(new Date())
      : timestamp_end.fromDate(state.filters.time.end.toJSDate());
    req.setEnd(timestamp_end);

    const timestamp_begin = new Timestamp();
    state.filters.time.begin === "default"
      ? timestamp_begin.fromDate(
          DateTime.local().minus({ months: 1 }).toJSDate()
        )
      : timestamp_begin.fromDate(state.filters.time.begin.toJSDate());
    req.setBegin(timestamp_begin);
    machineClient.outages(req, {}).then(
      (response) => {
        commit("SET_OUTAGES", response.toObject().outageBinMap);
        commit("SET_OUTAGE_LIST", response.toObject().outagesList);
        commit("SET_MACHINE_BINS", response.toObject().machineBinMap);
        commit("SET_LOADING", false);
        resolve();
      },
      (error) => {
        console.error("fetching outages", error);
        reject(error);
      }
    );
  });
};

const updateFilterData = ({ commit, dispatch }, data) => {
  commit("UPDATE_FILTER_DATA", data);
  dispatch("loadOutages");
};

export default {
  loadOutages,
  updateFilterData,
};
