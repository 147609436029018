import helpers from "../recipe/helpers";

const SET_RECIPES = (state, recipes) => {
  state.recipes = recipes;
};

const SET_RECIPE_FILTER = (state, filterValue) => {
  state.recipeFilter.filterValue = filterValue;
};

const SET_RECIPE_HAS_JOB = (state, data) => {
  const idx = state.recipes.findIndex((elem) => elem.name === data.recipe);
  state.recipes[idx].isUsedInJobs = data.hasJob;
};

const SET_IS_USED_IN_JOBS = (state, recipeName) => {
  const recipe = state.recipes.find((r) => r.name === recipeName);
  if (recipe) {
    state.currentRecipe.isUsedInJobs = recipe.isUsedInJobs;
  }
};

const SET_SIGN_RECIPE = (state, data) => {
  const now = new Date();
  const seconds = Math.floor(now.getTime() / 1000);
  state.currentRecipe.signedUsersList.push({
    user: data.email,
    signed: {
      seconds: seconds,
      nanos: 0,
    },
  });
};

const ADD_RECIPE = (state, recipe) => {
  state.recipes.unshift(recipe);
};

const DELETE_RECIPE = (state, recipe) => {
  const idx = state.recipes.findIndex((elem) => elem.name === recipe);
  state.recipes.splice(idx, 1);
};

const SET_DRAWING = (state, data) => {
  state.currentDrawing = {
    name: data.name,
    last_uploaded: data.last_uploaded,
  };
};

const SET_NAME_RECIPE = (state, data) => {
  const idx = state.recipes.findIndex((elem) => elem.name === data.oldName);
  state.recipes[idx].name = data.newName;
};

const SET_CURRENT_RECIPE = (state, recipe) => {
  state.currentRecipe = recipe;
};

const UPDATE_RECIPE = (state, recipe) => {
  state.currentRecipe = recipe;
};

const SET_TIME_FILTER = (state, data) => {
  state.timeFilter.start = data.start;
  state.timeFilter.end = data.end;
};

const SET_LOADING_RECIPES = (state, data) => {
  state.loadingRecipes = data;
};

//**********************//
// edit check mutations //
//**********************//
const SET_CHECK_COLOR = (state, data) => {
  //remove internal name from color array where it was previously set
  for (let i = 0; i < state.colors.length; i++) {
    for (let j = 0; j < state.colors[i].used.length; j++) {
      if (state.colors[i].used[j] === data.internalName) {
        state.colors[i].used.splice(j, 1);
      }
    }
  }
  //set internal name in color array with new color
  for (let i = 0; i < state.colors.length; i++) {
    if (state.colors[i].color === data.color) {
      state.colors[i].used.push(data.internalName);
    }
  }
  state.currentRecipe.geometryChecksList[data.idx].info.color = data.color;
};

const SET_PAGE = (state, page) => {
  state.addCheck.currentPage = page;
};

const SET_REFERENCE_CHECK = (state, check) => {
  state.addCheck.referenceCheck = check;
};

const SET_CAMERA = (state, camera) => {
  state.addCheck.camera = camera;
};

const SET_CHECK = (state, check) => {
  state.addCheck.check = check;
};

const SET_VIAL_TYPE = (state, type) => {
  state.addCheck.vialType = type;
};

const SET_INITIAL_COLORS = (state, data) => {
  Object.assign(state.colors, helpers.getDefaultState().colors); //reset colors array
  for (let i = 0; i < data.length; i++) {
    const idx = state.colors.findIndex(
      (elem) => elem.color === data[i].info.color
    );
    state.colors[idx].used.push(data[i].internalName);
  }
};

const SET_CHECK_VALUE = (state, data) => {
  if (data.type === "shortDescription") {
    state.currentRecipe.geometryChecksList[data.idx].info.shortDescription =
      data.newValue;
  } else if (data.type === "description") {
    state.currentRecipe.geometryChecksList[data.idx].info.description =
      data.newValue;
  } else if (data.type === "positiveTolerance") {
    state.currentRecipe.geometryChecksList[
      data.idx
    ].measurementRange.positiveTolerance = data.newValue;
  } else if (data.type === "negativeTolerance") {
    state.currentRecipe.geometryChecksList[
      data.idx
    ].measurementRange.negativeTolerance = data.newValue;
  } else if (data.type === "nominal") {
    state.currentRecipe.geometryChecksList[data.idx].measurementRange.nominal =
      data.newValue;
  } else if (data.type === "positiveWarningLimit") {
    state.currentRecipe.geometryChecksList[
      data.idx
    ].measurementRange.positiveWarningLimit = data.newValue;
  } else if (data.type === "negativeWarningLimit") {
    state.currentRecipe.geometryChecksList[
      data.idx
    ].measurementRange.negativeWarningLimit = data.newValue;
  } else if (data.type === "parameter1") {
    state.currentRecipe.geometryChecksList[
      data.idx
    ].additionalConfigurationByIdMap[0][1].value = data.newValue;
  } else if (data.type === "parameter2") {
    state.currentRecipe.geometryChecksList[
      data.idx
    ].additionalConfigurationByIdMap[1][1].value = data.newValue;
  }
};

const SET_CHECK_WARNING_LIMIT = (state, data) => {
  if (data.type === "positiveWarningLimit") {
    state.currentRecipe.geometryChecksList[
      data.idx
    ].measurementRange.positiveWarningLimitActive =
      data.newValue.measurementRange.positiveWarningLimitActive;
  } else if (data.type === "negativeWarningLimit") {
    state.currentRecipe.geometryChecksList[
      data.idx
    ].measurementRange.negativeWarningLimitActive =
      data.newValue.measurementRange.negativeWarningLimitActive;
  }
};

const SET_CHECK_AGGREGATION = (state, data) => {
  state.currentRecipe.geometryChecksList[data.idx].aggregationType =
    data.aggregation;
};

const DELETE_CHECK_COLOR = (state, internalName) => {
  //remove internal name from color array where it was previously set
  for (let i = 0; i < state.colors.length; i++) {
    for (let j = 0; j < state.colors[i].used.length; j++) {
      if (state.colors[i].used[j] === internalName) {
        state.colors[i].used.splice(j, 1);
      }
    }
  }
};

const DELETE_CHECK = (state, internalName) => {
  const idx = state.currentRecipe.geometryChecksList.findIndex(
    (elem) => elem.internalName === internalName
  );
  state.currentRecipe.geometryChecksList.splice(idx, 1);
};

const SET_CHECKS = (state, data) => {
  state.currentRecipe.geometryChecksList = data;
};

export default {
  SET_RECIPES,
  ADD_RECIPE,
  SET_RECIPE_HAS_JOB,
  DELETE_RECIPE,
  SET_NAME_RECIPE,
  SET_CURRENT_RECIPE,
  SET_CHECK_COLOR,
  SET_PAGE,
  SET_INITIAL_COLORS,
  SET_REFERENCE_CHECK,
  SET_CAMERA,
  SET_CHECK,
  SET_VIAL_TYPE,
  UPDATE_RECIPE,
  SET_CHECK_VALUE,
  SET_CHECK_WARNING_LIMIT,
  SET_CHECK_AGGREGATION,
  DELETE_CHECK_COLOR,
  DELETE_CHECK,
  SET_SIGN_RECIPE,
  SET_CHECKS,
  SET_DRAWING,
  SET_IS_USED_IN_JOBS,
  SET_TIME_FILTER,
  SET_LOADING_RECIPES,
  SET_RECIPE_FILTER,
};
