/**
 * @fileoverview gRPC-Web generated client stub for recipe
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var hosted_stores_productionplanning_rpc_proto_recipe_Recipe_pb = require('../../../../../../hosted/stores/productionplanning/rpc/proto/recipe/Recipe_pb.js')

var vialchecker_inspection_checks_base_proto_Geometry_pb = require('../../../../../../vialchecker/inspection/checks/base/proto/Geometry_pb.js')
const proto = {};
proto.recipe = require('./RecipeEditService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.recipe.RecipeEditClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.recipe.RecipeEditPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.recipe.PutGeometryCheckRequest,
 *   !proto.recipe.Recipe>}
 */
const methodDescriptor_RecipeEdit_Put = new grpc.web.MethodDescriptor(
  '/recipe.RecipeEdit/Put',
  grpc.web.MethodType.UNARY,
  proto.recipe.PutGeometryCheckRequest,
  hosted_stores_productionplanning_rpc_proto_recipe_Recipe_pb.Recipe,
  /**
   * @param {!proto.recipe.PutGeometryCheckRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  hosted_stores_productionplanning_rpc_proto_recipe_Recipe_pb.Recipe.deserializeBinary
);


/**
 * @param {!proto.recipe.PutGeometryCheckRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.recipe.Recipe)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.recipe.Recipe>|undefined}
 *     The XHR Node Readable Stream
 */
proto.recipe.RecipeEditClient.prototype.put =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/recipe.RecipeEdit/Put',
      request,
      metadata || {},
      methodDescriptor_RecipeEdit_Put,
      callback);
};


/**
 * @param {!proto.recipe.PutGeometryCheckRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.recipe.Recipe>}
 *     Promise that resolves to the response
 */
proto.recipe.RecipeEditPromiseClient.prototype.put =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/recipe.RecipeEdit/Put',
      request,
      metadata || {},
      methodDescriptor_RecipeEdit_Put);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.recipe.DeleteGeometryCheckRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_RecipeEdit_Delete = new grpc.web.MethodDescriptor(
  '/recipe.RecipeEdit/Delete',
  grpc.web.MethodType.UNARY,
  proto.recipe.DeleteGeometryCheckRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.recipe.DeleteGeometryCheckRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.recipe.DeleteGeometryCheckRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.recipe.RecipeEditClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/recipe.RecipeEdit/Delete',
      request,
      metadata || {},
      methodDescriptor_RecipeEdit_Delete,
      callback);
};


/**
 * @param {!proto.recipe.DeleteGeometryCheckRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.recipe.RecipeEditPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/recipe.RecipeEdit/Delete',
      request,
      metadata || {},
      methodDescriptor_RecipeEdit_Delete);
};


module.exports = proto.recipe;

