const getDefaultState = () => {
  return {
    filters: {
      time: {
        end: "now",
        begin: "default",
      },
      machines: ["all"],
      shifts: ["all"],
    },
    loading: false,
    outages: [],
    outageList: [],
    machineBins: [],
  };
};

module.exports = {
  getDefaultState,
};
