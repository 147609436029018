import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-46e25d59"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "central-app"
};
const _hoisted_2 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_site_header = _resolveComponent("site-header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_side_navigation = _resolveComponent("side-navigation");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    id: $setup.isTablet ? '' : 'view',
    class: _normalizeClass([{
      collapsed: $setup.isCollapsed
    }])
  }, [!$setup.isLogin ? (_openBlock(), _createBlock(_component_site_header, {
    key: 0
  })) : _createCommentVNode("", true), _createVNode(_component_router_view)], 10, _hoisted_2), !$setup.isLogin ? (_openBlock(), _createBlock(_component_side_navigation, {
    key: 0
  })) : _createCommentVNode("", true)]);
}