// The UnaryInterceptor interface is for the promise-based client.
import store from "../store";

const UnaryInterceptor = function () {};
UnaryInterceptor.prototype.intercept = function (request, invoker) {
  // Update the request message before the RPC.
  let token = store.state.base.jwt;
  if (token) {
    const metadata = request.getMetadata();
    metadata.Authorization = "Bearer " + token;
  }
  // After the RPC returns successfully, update the response.
  return invoker(request).then(
    (response) => {
      const auth = response.getMetadata().authorization;
      if (auth && auth.includes("NewToken")) {
        store.commit("base/SET_TOKEN", auth.slice(9, auth.length));
      }
      return response;
    },
    (error) => {
      if (error.code === 2) {
        console.error("connection to server is currently down");
      } else if (error.code === 16) {
        //token is tampered with, expired, or database data has changed
        const metadata = request.getMetadata();
        metadata.Authorization = "";
        store
          .dispatch("tracking/sendTracking", {
            event: "logout",
            note: "token is expired, tampered with or database change has happened",
          })
          .catch((error) => {
            console.error("sendTracking error", error);
          });
        store.commit("base/LOGOUT"); //logout user, access is no longer allowed in website
      }
      //error from back end, but new token is sent with error...should be refreshed on front end
      const auth = error.metadata.authorization;
      if (auth && auth.includes("NewToken")) {
        store.commit("base/SET_TOKEN", auth.slice(9, auth.length));
      }
      return Promise.reject(error);
    }
  );
};

export default UnaryInterceptor;
