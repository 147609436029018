import rootHelper from "./helpers";
import outageHelper from "../../modules/outages/helpers";
import jobPlanningHelper from "../../modules/productionPlanning/jobPlanning/helpers";
import recipeHelper from "../../modules/productionPlanning/recipe/helpers";
import cosmeticHelper from "../../modules/cosmetic/helpers";
import productionStatisticsHelper from "../../modules/dashboard/productionStatistics/helpers";
import trackingHelper from "../../modules/tracking/helpers";
import router from "@/router";
import store from "@/store";

const LOGIN = (state, data) => {
  state.authenticated = true; //authenticate user to use the website
  state.userInfo.email = data.user.email;
  state.userInfo.firstName = data.user.firstName;
  state.userInfo.lastName = data.user.lastName;
  state.userInfo.timeZone = data.user.timeZone;
  state.userInfo.companyUuid = data.user.companyUuid;
  state.userInfo.companyName = data.user.companyName;
  state.features.recipeAuthorization = data.user.features.recipeAuthorization;
  if (data.user.shifts !== "") {
    state.userInfo.shifts = JSON.parse(data.user.shifts);
  }

  state.admin = data.user.isAdmin;
  state.userInfo.permissions = {
    classification: {
      page: data.user.permissions.classification.page,
    },
    dashboard: {
      page: data.user.permissions.dashboard.page,
      statistics: data.user.permissions.dashboard.statistics,
    },
    printouts: {
      page: data.user.permissions.printouts.page,
      validationPrintout: data.user.permissions.printouts.validationPrintout,
    },
    productionPlanning: {
      page: data.user.permissions.productionPlanning.page,
      scheduling: data.user.permissions.productionPlanning.scheduling,
      recipeApproval: data.user.permissions.productionPlanning.recipeApproval,
      recipeApprovalSuper:
        data.user.permissions.productionPlanning.recipeApprovalSuper,
    },
    outages: {
      page: data.user.permissions.outages.page,
    },
    level: {
      super: data.user.permissions.level.pb_super,
    },
  };
  state.jwt = data.jwt;
};

const LOGOUT = () => {
  if (router.currentRoute.value.name === "login") {
    return;
  }
  router
    .push({
      name: "login",
    })
    .then(() => {
      let newState = Object.assign(
        {},
        {
          base: rootHelper.getDefaultState(),
          outages: outageHelper.getDefaultState(),
          jobPlanning: jobPlanningHelper.getDefaultState(),
          recipe: recipeHelper.getDefaultState(),
          cosmetic: cosmeticHelper.getDefaultState(),
          tracking: trackingHelper.getDefaultState(),
          productionStatistics: productionStatisticsHelper.getDefaultState(),
        }
      );
      store.replaceState(newState);
    })
    .catch((err) => {
      console.error("error logging out", err);
    });
};

const UPDATE_USER_GENERAL = (state, data) => {
  state.userInfo.firstName = data.firstName;
  state.userInfo.lastName = data.lastName;
};

const SET_USER_SHIFTS = (state, data) => {
  state.userInfo.shifts = data;
};

const SET_TOKEN = (state, token) => {
  state.jwt = token;
};

const SET_MOBILE = (state, isMobile) => {
  state.isMobile = isMobile;
};

const SET_TABLET = (state, isTablet) => {
  state.isTablet = isTablet;
};

const SET_MENU_COLLAPSE = (state, collapsed) => {
  state.menu.collapsed = collapsed;
};

const SET_USER_EMAIL = (state) => {
  state.userInfo.email = null;
};

const SET_MACHINES = (state, machines) => {
  state.machines = machines;
};

export default {
  LOGIN,
  LOGOUT,
  SET_TOKEN,
  SET_MOBILE,
  SET_TABLET,
  SET_MENU_COLLAPSE,
  UPDATE_USER_GENERAL,
  SET_USER_EMAIL,
  SET_MACHINES,
  SET_USER_SHIFTS,
};
