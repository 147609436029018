const SET_TIME = (state, time) => {
  state.time = time;
};

const SET_QUERY_TIME = (state, time) => {
  state.queryTime = time;
};

const SET_SORT = (state, sort) => {
  state.sort = sort;
};

const SET_QUERY_SORT = (state, sort) => {
  state.querySort = sort;
};

const SET_DEFECT_TYPE = (state, type) => {
  state.defectType = type;
};

const SET_MANUAL_AUTO = (state, manual) => {
  state.manualOrAuto = manual;
};

export default {
  SET_TIME,
  SET_SORT,
  SET_QUERY_TIME,
  SET_QUERY_SORT,
  SET_DEFECT_TYPE,
  SET_MANUAL_AUTO,
};
