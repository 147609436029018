const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/components/simpleViews/LoginView"),
  },
];

export default routes.map((route) => {
  const meta = {
    is_admin: false,
    requires_auth: false,
  };
  return { ...route, meta };
});
