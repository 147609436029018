import { uuid, mod } from "@/helper";

const LOGGED_IN = (state) => !!state.jwt;
const GET_MOBILE = (state) => state.isMobile;

const SHIFT_TILES = (state) => {
  let shifts = state.userInfo.shifts;
  let shiftTiles = [];
  for (let i = 0; i < shifts.length; i++) {
    for (let j = 0; j < shifts[i].length; j++) {
      let setEnd = "";
      if (shifts[i][j].continued === true && shifts[i][j].end === "00:00") {
        let idx = shifts[mod(i + 1, 7)].findIndex((e) => {
          return e.start === "00:00" && e.continued === true;
        });
        setEnd = shifts[mod(i + 1, 7)][idx].end;
      } else {
        setEnd = shifts[i][j].end;
      }

      if (shifts[i][j].continued === true && shifts[i][j].start === "00:00") {
        //do nothing - skip saving second have of continued days
      } else {
        let shiftIdx = shiftTiles.findIndex(
          (e) => e.startTime === shifts[i][j].start && e.endTime === setEnd
        );
        if (
          shiftIdx !== -1 &&
          shifts[i][j].name === shiftTiles[shiftIdx].name
        ) {
          shiftTiles[shiftIdx].days[i].selected = true;
        } else {
          let days = [
            {
              name: "Sun",
              selected: false,
            },
            {
              name: "Mon",
              selected: false,
            },
            {
              name: "Tue",
              selected: false,
            },
            {
              name: "Wed",
              selected: false,
            },
            {
              name: "Thu",
              selected: false,
            },
            {
              name: "Fri",
              selected: false,
            },
            {
              name: "Sat",
              selected: false,
            },
          ];
          days[i].selected = true;
          shiftTiles.push({
            id: uuid(),
            days,
            startTime: shifts[i][j].start,
            endTime: setEnd,
            error: null,
            name: shifts[i][j].name,
          });
        }
      }
    }
  }
  return shiftTiles;
};

export default {
  LOGGED_IN,
  GET_MOBILE,
  SHIFT_TILES,
};
