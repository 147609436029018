import { ProductionHistoryRequest } from "@/pb/isotronic/hosted/central/gatekeeper/manufacturingapi/rpc/proto/AnalyticsService_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import {
  convertTime,
  squashDuplicates,
  addZeroValues,
} from "@/store/modules/dashboard/productionStatistics/helpers";
import { DateTime } from "luxon";

function currentRunningJob(timeSpan) {
  //check to see if we have a currently running job
  let timeDifference = 1801;
  if (timeSpan !== undefined && timeSpan.end) {
    let currentTimeInSeconds = DateTime.utc().toSeconds();
    let givenTimeInSeconds = timeSpan.end.ts / 1000; // assuming timeSpan.end has a seconds property
    timeDifference = Math.abs(currentTimeInSeconds - givenTimeInSeconds);
  }
  return timeDifference;
}

export function prepareRequest(rootState, state, timeSpan) {
  const productionHistoryRequest = new ProductionHistoryRequest();
  productionHistoryRequest.setCustomer(rootState.base.userInfo.companyUuid);
  productionHistoryRequest.setMachineName(state.selectedMachine);

  const timestampBegin = new Timestamp();
  const timestampEnd = new Timestamp();
  let definedTimespan = timeSpan !== undefined;

  //check to see if we have a currently running job
  let timeDifference = currentRunningJob(timeSpan);
  if (timeSpan === undefined) {
    productionHistoryRequest.setTimeInterval(10);
    let date = new Date();
    date.setDate(date.getDate() - 5);
    timestampBegin.fromDate(date);
  } else {
    if (timeSpan.begin === undefined || timeSpan.end === undefined) {
      throw new Error("timespan beginning and end need to be defined");
    }
    timeDifference <= 1800
      ? productionHistoryRequest.setTimeInterval(10)
      : productionHistoryRequest.setTimeInterval(100000);
    timestampBegin.fromDate(timeSpan.begin.toJSDate());
    timestampEnd.fromDate(timeSpan.end.toJSDate());
  }
  productionHistoryRequest.setBegin(timestampBegin);
  productionHistoryRequest.setEnd(timestampEnd);

  if (state.jobList.selectedJob !== -1) {
    productionHistoryRequest.setJob(
      state.jobList.jobs[state.jobList.selectedJob].jobName
    );
  }

  return {
    productionHistoryRequest,
    definedTimespan,
    timestampBegin,
    timestampEnd,
  };
}

export function handleData(res, defectList, dataArrays, timeZone) {
  dataArrays.dates.push(convertTime(res.begin, "yyyy-LL-dd HH:mm", timeZone));
  dataArrays.geometricCheckBins.push(res.geometricChecksList);
  for (let i = 0; i < res.geometricChecksList.length; i++) {
    defectList.set(res.geometricChecksList[i].internalName, {
      shortName: res.geometricChecksList[i].shortName,
      longName: res.geometricChecksList[i].longName,
    });
  }
  dataArrays.cosmeticCheckBins.push(res.cosmeticChecksList);
  for (let i = 0; i < res.cosmeticChecksList.length; i++) {
    defectList.set(res.cosmeticChecksList[i].internalName, {
      shortName: res.cosmeticChecksList[i].shortName,
      longName: res.cosmeticChecksList[i].longName,
    });
  }
  dataArrays.jobs.push(res.jobName);
  dataArrays.produced.push(res.productionCount);
  dataArrays.rejected.push(res.rejectionCount); //this shows all defects regardless if cosmetic or geometric
  dataArrays.percentRejected.push(
    (
      (res.rejectionCount / (res.rejectionCount + res.productionCount)) *
      100
    ).toFixed(2)
  );
}

export async function finalizeData(
  commit,
  state,
  defectList,
  dataArrays,
  definedTimespan,
  timestampBegin,
  timestampEnd,
  timeZone,
  timeSpan,
  initialLoad
) {
  let data = [];
  data.valueDataProduced = dataArrays.produced;
  data.valueDataRejected = dataArrays.rejected;
  data.valuePercentRejected = dataArrays.percentRejected;
  data.categoryDates = dataArrays.dates;
  data.geometricCheckBins = dataArrays.geometricCheckBins;
  data.cosmeticCheckBins = dataArrays.cosmeticCheckBins;
  data.jobs = dataArrays.jobs;
  commit("SET_DEFECT_MAP", defectList);
  let squashedData = await squashDuplicates(data);
  let zeroedData = await addZeroValues(
    squashedData,
    timeZone,
    timestampBegin,
    timestampEnd
  );

  commit("SET_PRODUCTION_GRAPH_DATA", zeroedData);
  let timeDifference = currentRunningJob(timeSpan);
  //if we have no selected job, or the job is in the past, also save this to production data
  if (state.jobList.selectedJob === -1 || timeDifference <= 1800) {
    commit("SET_SHIFT_PRODUCTION_DATA", zeroedData);
  }
  let startIndex, endIndex;
  if (definedTimespan) {
    startIndex = 0;
    endIndex = zeroedData.categoryDates.length - 1;
  } else if (state.pageFilterDropdown.start || state.pageFilterDropdown.end) {
    startIndex =
      zeroedData.categoryDates.length - state.pageFilterDropdown.start;
    endIndex = zeroedData.categoryDates.length - state.pageFilterDropdown.end;
  } else {
    startIndex =
      zeroedData.categoryDates.length > 24
        ? zeroedData.categoryDates.length - 24
        : 0;
    endIndex =
      zeroedData.categoryDates.length > 0
        ? zeroedData.categoryDates.length - 1
        : 0;
  }
  //don't update initial zoom values unless this the initial load or manual zoomed
  if (initialLoad === false || state.manualZoom) {
    commit("SET_ZOOM_DATA", {
      startIndex: state.zoom.startIndex,
      endIndex: state.zoom.endIndex,
    });
  } else {
    commit("SET_ZOOM_DATA", {
      startIndex: startIndex,
      endIndex: endIndex,
    });
    commit("SET_MANUAL_ZOOM", false);
  }
  commit("SET_PRODUCTION_LOADING", false);

  // Empty the data arrays
  dataArrays.produced.length = 0;
  dataArrays.rejected.length = 0;
  dataArrays.percentRejected.length = 0;
  dataArrays.dates.length = 0;
  dataArrays.jobs.length = 0;
  dataArrays.geometricCheckBins.length = 0;
  dataArrays.cosmeticCheckBins.length = 0;
}
