/**
 * @fileoverview gRPC-Web generated client stub for user
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var hosted_central_gatekeeper_authorizationapi_rpc_proto_user_User_pb = require('../../../../../../../hosted/central/gatekeeper/authorizationapi/rpc/proto/user/User_pb.js')
const proto = {};
proto.user = require('./UserManagementService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user.UserManagementClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user.UserManagementPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.PutUserRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserManagement_Put = new grpc.web.MethodDescriptor(
  '/user.UserManagement/Put',
  grpc.web.MethodType.UNARY,
  proto.user.PutUserRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.user.PutUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.user.PutUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserManagementClient.prototype.put =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.UserManagement/Put',
      request,
      metadata || {},
      methodDescriptor_UserManagement_Put,
      callback);
};


/**
 * @param {!proto.user.PutUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.user.UserManagementPromiseClient.prototype.put =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.UserManagement/Put',
      request,
      metadata || {},
      methodDescriptor_UserManagement_Put);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.LoginUserRequest,
 *   !proto.user.LoginUserResponse>}
 */
const methodDescriptor_UserManagement_LoginUser = new grpc.web.MethodDescriptor(
  '/user.UserManagement/LoginUser',
  grpc.web.MethodType.UNARY,
  proto.user.LoginUserRequest,
  proto.user.LoginUserResponse,
  /**
   * @param {!proto.user.LoginUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user.LoginUserResponse.deserializeBinary
);


/**
 * @param {!proto.user.LoginUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.LoginUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.LoginUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserManagementClient.prototype.loginUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.UserManagement/LoginUser',
      request,
      metadata || {},
      methodDescriptor_UserManagement_LoginUser,
      callback);
};


/**
 * @param {!proto.user.LoginUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.LoginUserResponse>}
 *     Promise that resolves to the response
 */
proto.user.UserManagementPromiseClient.prototype.loginUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.UserManagement/LoginUser',
      request,
      metadata || {},
      methodDescriptor_UserManagement_LoginUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserManagement_VerifyUser = new grpc.web.MethodDescriptor(
  '/user.UserManagement/VerifyUser',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserManagementClient.prototype.verifyUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.UserManagement/VerifyUser',
      request,
      metadata || {},
      methodDescriptor_UserManagement_VerifyUser,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.user.UserManagementPromiseClient.prototype.verifyUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.UserManagement/VerifyUser',
      request,
      metadata || {},
      methodDescriptor_UserManagement_VerifyUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.UpdateGeneralRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserManagement_UpdateGeneral = new grpc.web.MethodDescriptor(
  '/user.UserManagement/UpdateGeneral',
  grpc.web.MethodType.UNARY,
  proto.user.UpdateGeneralRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.user.UpdateGeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.user.UpdateGeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserManagementClient.prototype.updateGeneral =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.UserManagement/UpdateGeneral',
      request,
      metadata || {},
      methodDescriptor_UserManagement_UpdateGeneral,
      callback);
};


/**
 * @param {!proto.user.UpdateGeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.user.UserManagementPromiseClient.prototype.updateGeneral =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.UserManagement/UpdateGeneral',
      request,
      metadata || {},
      methodDescriptor_UserManagement_UpdateGeneral);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.UpdatePasswordRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserManagement_UpdatePassword = new grpc.web.MethodDescriptor(
  '/user.UserManagement/UpdatePassword',
  grpc.web.MethodType.UNARY,
  proto.user.UpdatePasswordRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.user.UpdatePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.user.UpdatePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserManagementClient.prototype.updatePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.UserManagement/UpdatePassword',
      request,
      metadata || {},
      methodDescriptor_UserManagement_UpdatePassword,
      callback);
};


/**
 * @param {!proto.user.UpdatePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.user.UserManagementPromiseClient.prototype.updatePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.UserManagement/UpdatePassword',
      request,
      metadata || {},
      methodDescriptor_UserManagement_UpdatePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.user.UserListResponse>}
 */
const methodDescriptor_UserManagement_GetUserList = new grpc.web.MethodDescriptor(
  '/user.UserManagement/GetUserList',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.user.UserListResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user.UserListResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.UserListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.UserListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserManagementClient.prototype.getUserList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.UserManagement/GetUserList',
      request,
      metadata || {},
      methodDescriptor_UserManagement_GetUserList,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.UserListResponse>}
 *     Promise that resolves to the response
 */
proto.user.UserManagementPromiseClient.prototype.getUserList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.UserManagement/GetUserList',
      request,
      metadata || {},
      methodDescriptor_UserManagement_GetUserList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.UserInfo,
 *   !proto.user.User>}
 */
const methodDescriptor_UserManagement_GetUserInfo = new grpc.web.MethodDescriptor(
  '/user.UserManagement/GetUserInfo',
  grpc.web.MethodType.UNARY,
  proto.user.UserInfo,
  hosted_central_gatekeeper_authorizationapi_rpc_proto_user_User_pb.User,
  /**
   * @param {!proto.user.UserInfo} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  hosted_central_gatekeeper_authorizationapi_rpc_proto_user_User_pb.User.deserializeBinary
);


/**
 * @param {!proto.user.UserInfo} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserManagementClient.prototype.getUserInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.UserManagement/GetUserInfo',
      request,
      metadata || {},
      methodDescriptor_UserManagement_GetUserInfo,
      callback);
};


/**
 * @param {!proto.user.UserInfo} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.User>}
 *     Promise that resolves to the response
 */
proto.user.UserManagementPromiseClient.prototype.getUserInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.UserManagement/GetUserInfo',
      request,
      metadata || {},
      methodDescriptor_UserManagement_GetUserInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.UserInfo,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserManagement_Delete = new grpc.web.MethodDescriptor(
  '/user.UserManagement/Delete',
  grpc.web.MethodType.UNARY,
  proto.user.UserInfo,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.user.UserInfo} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.user.UserInfo} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserManagementClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.UserManagement/Delete',
      request,
      metadata || {},
      methodDescriptor_UserManagement_Delete,
      callback);
};


/**
 * @param {!proto.user.UserInfo} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.user.UserManagementPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.UserManagement/Delete',
      request,
      metadata || {},
      methodDescriptor_UserManagement_Delete);
};


module.exports = proto.user;

