const getDefaultState = () => {
  return {
    jwt: null,
    userInfo: {
      email: null,
      firstName: null,
      lastName: null,
      timeZone: null,
      companyUuid: null,
      companyName: null,
      permissions: null,
      shifts: [],
    },
    features: {
      recipeAuthorization: false,
    },
    menu: {
      collapsed: true,
    },
    authenticated: false,
    admin: null,
    isMobile: false,
    isTablet: false,
    machines: null,
  };
};

module.exports = {
  getDefaultState,
};
