import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createBootstrap } from "bootstrap-vue-next";
import options from "./interceptors";
import FloatingVue from "floating-vue"; //workaround for tooltip
FloatingVue.options.placement = "auto";

import Echarts from "vue-echarts";
import "@/plugins/echarts";

import "@/icons/font-awesome.js";

import "floating-vue/dist/style.css"; //workaround for tooltip
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "./styles/global.sass";

export const app = createApp(App);
app.use(router);
app.use(store);
app.use(FloatingVue); //workaround for tooltip
// app.use(createBootstrap({ components: true, directives: true }));
app.use(createBootstrap());
// app.use(TawkMessengerVue, {
//   propertyId: "64592d9aad80445890ebc7b2",
//   widgetId: "1gvu5k959",
// });
app.component("chart-component", Echarts);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("tree-select", Treeselect);
app.config.globalProperties.$interceptOptions = options;
app.provide("interceptOptions", options);
store.$interceptOptions = options;

app.mount("#app");
