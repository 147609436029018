import { mapActions, mapMutations, mapState } from "vuex";
export default {
  methods: {
    ...mapMutations("base", ["SET_MENU_COLLAPSE"]),
    ...mapActions("base", {
      logoutUser: "logout"
    }),
    logout() {
      this.logoutUser();
    }
  },
  computed: {
    ...mapState("base", {
      admin: state => state.admin,
      collapsed: state => state.menu.collapsed,
      isTablet: state => state.isTablet
    })
  }
};