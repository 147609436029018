import { dom, library } from "@fortawesome/fontawesome-svg-core";

import {
  faBars,
  faCalendar,
  faChartLine,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faExclamationCircle,
  faImages,
  faThumbsUp,
  faRefresh,
  faEllipsisH,
  faPaperPlane,
  faPrint,
  faFilter,
  faTableColumns,
  faSitemap,
  faTrash,
  faUpload,
  faDownload,
  faUndo,
  faClock,
  faEdit,
  faTimes,
  faChartSimple,
  faInfoCircle,
  faSearch,
  faSpinner,
  faPlusCircle,
  faCheck,
  faCog,
  faCopy,
  faInfo,
  faArrowLeft,
  faMinus,
  faPlus,
  faChartPie,
  faTable,
  faSquareCheck as faSolidSquareCheck,
} from "@fortawesome/free-solid-svg-icons";

import { faSquareCheck } from "@fortawesome/free-regular-svg-icons";

dom.watch();
library.add(
  faTimes,
  faCalendar,
  faInfoCircle,
  faFilter,
  faChartSimple,
  faTableColumns,
  faThumbsUp,
  faChartLine,
  faEllipsisH,
  faUpload,
  faEdit,
  faDownload,
  faPrint,
  faRefresh,
  faSearch,
  faUndo,
  faSquareCheck,
  faSolidSquareCheck,
  faTrash,
  faExclamationTriangle,
  faExclamationCircle,
  faImages,
  faPaperPlane,
  faSitemap,
  faSpinner,
  faChevronRight,
  faBars,
  faClock,
  faChevronLeft,
  faPlusCircle,
  faCheck,
  faCog,
  faCopy,
  faInfo,
  faArrowLeft,
  faMinus,
  faPlus,
  faChartPie,
  faTable
);
