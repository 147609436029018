import { DateTime } from "luxon";

const getDropdownShiftData = (state) => {
  let dropdownShiftData = [];
  let dates = state.shiftData.categoryDates;
  state.shiftData.computedShiftData.forEach((shift) => {
    let shiftDate = DateTime.fromFormat(shift.date, "dd/MM/yyyy");
    let startDateString =
      shiftDate.toFormat("yyyy-MM-dd") + " " + shift.shift.substring(0, 5);
    // Check if it's an overnight shift (i.e., if the end hour is less than the start hour)
    let [startHour, endHour] = shift.shift
      .split(" - ")
      .map((time) => parseInt(time.split(":")[0]));
    if (!isNaN(endHour) && endHour < startHour) {
      // if it's an overnight shift
      shiftDate = shiftDate.plus({ days: 1 });
    }
    let endDateString =
      shiftDate.toFormat("yyyy-MM-dd") + " " + shift.shift.split(" - ")[1];
    let shiftEndIndex = dates.indexOf(endDateString);
    let shiftObj = {
      start: dates.length - dates.indexOf(startDateString),
      end: shiftEndIndex === -1 ? 1 : dates.length - shiftEndIndex + 1, //add 1 to round to last 30 min interval since bin at end time should not be included
      text: shift.name,
    };
    dropdownShiftData.push(shiftObj);
  });
  return dropdownShiftData;
};

export default {
  getDropdownShiftData,
};
