// source: hosted/central/gatekeeper/authorizationapi/rpc/proto/user/User.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.user.CentralPermissions', null, global);
goog.exportSymbol('proto.user.Classification', null, global);
goog.exportSymbol('proto.user.Dashboard', null, global);
goog.exportSymbol('proto.user.Features', null, global);
goog.exportSymbol('proto.user.Level', null, global);
goog.exportSymbol('proto.user.Outages', null, global);
goog.exportSymbol('proto.user.Printouts', null, global);
goog.exportSymbol('proto.user.ProductionPlanning', null, global);
goog.exportSymbol('proto.user.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.User.displayName = 'proto.user.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Features = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Features, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Features.displayName = 'proto.user.Features';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.CentralPermissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.CentralPermissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.CentralPermissions.displayName = 'proto.user.CentralPermissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Dashboard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Dashboard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Dashboard.displayName = 'proto.user.Dashboard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Classification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Classification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Classification.displayName = 'proto.user.Classification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Printouts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Printouts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Printouts.displayName = 'proto.user.Printouts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ProductionPlanning = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.ProductionPlanning, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ProductionPlanning.displayName = 'proto.user.ProductionPlanning';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Outages = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Outages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Outages.displayName = 'proto.user.Outages';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Level = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Level, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Level.displayName = 'proto.user.Level';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.User.prototype.toObject = function(opt_includeInstance) {
  return proto.user.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    timeZone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    permissions: (f = msg.getPermissions()) && proto.user.CentralPermissions.toObject(includeInstance, f),
    companyUuid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    shifts: jspb.Message.getFieldWithDefault(msg, 9, ""),
    features: (f = msg.getFeatures()) && proto.user.Features.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.User}
 */
proto.user.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.User;
  return proto.user.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.User}
 */
proto.user.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdmin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    case 6:
      var value = new proto.user.CentralPermissions;
      reader.readMessage(value,proto.user.CentralPermissions.deserializeBinaryFromReader);
      msg.setPermissions(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyUuid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setShifts(value);
      break;
    case 10:
      var value = new proto.user.Features;
      reader.readMessage(value,proto.user.Features.deserializeBinaryFromReader);
      msg.setFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsAdmin();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPermissions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.user.CentralPermissions.serializeBinaryToWriter
    );
  }
  f = message.getCompanyUuid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getShifts();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.user.Features.serializeBinaryToWriter
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.user.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.user.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.user.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_admin = 4;
 * @return {boolean}
 */
proto.user.User.prototype.getIsAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setIsAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string time_zone = 5;
 * @return {string}
 */
proto.user.User.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional CentralPermissions permissions = 6;
 * @return {?proto.user.CentralPermissions}
 */
proto.user.User.prototype.getPermissions = function() {
  return /** @type{?proto.user.CentralPermissions} */ (
    jspb.Message.getWrapperField(this, proto.user.CentralPermissions, 6));
};


/**
 * @param {?proto.user.CentralPermissions|undefined} value
 * @return {!proto.user.User} returns this
*/
proto.user.User.prototype.setPermissions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.clearPermissions = function() {
  return this.setPermissions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.User.prototype.hasPermissions = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string company_uuid = 7;
 * @return {string}
 */
proto.user.User.prototype.getCompanyUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setCompanyUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string company_name = 8;
 * @return {string}
 */
proto.user.User.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string shifts = 9;
 * @return {string}
 */
proto.user.User.prototype.getShifts = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setShifts = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional Features features = 10;
 * @return {?proto.user.Features}
 */
proto.user.User.prototype.getFeatures = function() {
  return /** @type{?proto.user.Features} */ (
    jspb.Message.getWrapperField(this, proto.user.Features, 10));
};


/**
 * @param {?proto.user.Features|undefined} value
 * @return {!proto.user.User} returns this
*/
proto.user.User.prototype.setFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.clearFeatures = function() {
  return this.setFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.User.prototype.hasFeatures = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Features.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Features.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Features} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Features.toObject = function(includeInstance, msg) {
  var f, obj = {
    recipeAuthorization: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Features}
 */
proto.user.Features.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Features;
  return proto.user.Features.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Features} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Features}
 */
proto.user.Features.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecipeAuthorization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Features.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Features.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Features} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Features.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecipeAuthorization();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool recipe_authorization = 1;
 * @return {boolean}
 */
proto.user.Features.prototype.getRecipeAuthorization = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.Features} returns this
 */
proto.user.Features.prototype.setRecipeAuthorization = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.CentralPermissions.prototype.toObject = function(opt_includeInstance) {
  return proto.user.CentralPermissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.CentralPermissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CentralPermissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboard: (f = msg.getDashboard()) && proto.user.Dashboard.toObject(includeInstance, f),
    classification: (f = msg.getClassification()) && proto.user.Classification.toObject(includeInstance, f),
    printouts: (f = msg.getPrintouts()) && proto.user.Printouts.toObject(includeInstance, f),
    productionPlanning: (f = msg.getProductionPlanning()) && proto.user.ProductionPlanning.toObject(includeInstance, f),
    outages: (f = msg.getOutages()) && proto.user.Outages.toObject(includeInstance, f),
    level: (f = msg.getLevel()) && proto.user.Level.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.CentralPermissions}
 */
proto.user.CentralPermissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.CentralPermissions;
  return proto.user.CentralPermissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.CentralPermissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.CentralPermissions}
 */
proto.user.CentralPermissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.Dashboard;
      reader.readMessage(value,proto.user.Dashboard.deserializeBinaryFromReader);
      msg.setDashboard(value);
      break;
    case 2:
      var value = new proto.user.Classification;
      reader.readMessage(value,proto.user.Classification.deserializeBinaryFromReader);
      msg.setClassification(value);
      break;
    case 3:
      var value = new proto.user.Printouts;
      reader.readMessage(value,proto.user.Printouts.deserializeBinaryFromReader);
      msg.setPrintouts(value);
      break;
    case 4:
      var value = new proto.user.ProductionPlanning;
      reader.readMessage(value,proto.user.ProductionPlanning.deserializeBinaryFromReader);
      msg.setProductionPlanning(value);
      break;
    case 5:
      var value = new proto.user.Outages;
      reader.readMessage(value,proto.user.Outages.deserializeBinaryFromReader);
      msg.setOutages(value);
      break;
    case 6:
      var value = new proto.user.Level;
      reader.readMessage(value,proto.user.Level.deserializeBinaryFromReader);
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.CentralPermissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.CentralPermissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.CentralPermissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CentralPermissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.user.Dashboard.serializeBinaryToWriter
    );
  }
  f = message.getClassification();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.user.Classification.serializeBinaryToWriter
    );
  }
  f = message.getPrintouts();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.user.Printouts.serializeBinaryToWriter
    );
  }
  f = message.getProductionPlanning();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.user.ProductionPlanning.serializeBinaryToWriter
    );
  }
  f = message.getOutages();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.user.Outages.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.user.Level.serializeBinaryToWriter
    );
  }
};


/**
 * optional Dashboard dashboard = 1;
 * @return {?proto.user.Dashboard}
 */
proto.user.CentralPermissions.prototype.getDashboard = function() {
  return /** @type{?proto.user.Dashboard} */ (
    jspb.Message.getWrapperField(this, proto.user.Dashboard, 1));
};


/**
 * @param {?proto.user.Dashboard|undefined} value
 * @return {!proto.user.CentralPermissions} returns this
*/
proto.user.CentralPermissions.prototype.setDashboard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.CentralPermissions} returns this
 */
proto.user.CentralPermissions.prototype.clearDashboard = function() {
  return this.setDashboard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.CentralPermissions.prototype.hasDashboard = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Classification classification = 2;
 * @return {?proto.user.Classification}
 */
proto.user.CentralPermissions.prototype.getClassification = function() {
  return /** @type{?proto.user.Classification} */ (
    jspb.Message.getWrapperField(this, proto.user.Classification, 2));
};


/**
 * @param {?proto.user.Classification|undefined} value
 * @return {!proto.user.CentralPermissions} returns this
*/
proto.user.CentralPermissions.prototype.setClassification = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.CentralPermissions} returns this
 */
proto.user.CentralPermissions.prototype.clearClassification = function() {
  return this.setClassification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.CentralPermissions.prototype.hasClassification = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Printouts printouts = 3;
 * @return {?proto.user.Printouts}
 */
proto.user.CentralPermissions.prototype.getPrintouts = function() {
  return /** @type{?proto.user.Printouts} */ (
    jspb.Message.getWrapperField(this, proto.user.Printouts, 3));
};


/**
 * @param {?proto.user.Printouts|undefined} value
 * @return {!proto.user.CentralPermissions} returns this
*/
proto.user.CentralPermissions.prototype.setPrintouts = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.CentralPermissions} returns this
 */
proto.user.CentralPermissions.prototype.clearPrintouts = function() {
  return this.setPrintouts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.CentralPermissions.prototype.hasPrintouts = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProductionPlanning production_planning = 4;
 * @return {?proto.user.ProductionPlanning}
 */
proto.user.CentralPermissions.prototype.getProductionPlanning = function() {
  return /** @type{?proto.user.ProductionPlanning} */ (
    jspb.Message.getWrapperField(this, proto.user.ProductionPlanning, 4));
};


/**
 * @param {?proto.user.ProductionPlanning|undefined} value
 * @return {!proto.user.CentralPermissions} returns this
*/
proto.user.CentralPermissions.prototype.setProductionPlanning = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.CentralPermissions} returns this
 */
proto.user.CentralPermissions.prototype.clearProductionPlanning = function() {
  return this.setProductionPlanning(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.CentralPermissions.prototype.hasProductionPlanning = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Outages outages = 5;
 * @return {?proto.user.Outages}
 */
proto.user.CentralPermissions.prototype.getOutages = function() {
  return /** @type{?proto.user.Outages} */ (
    jspb.Message.getWrapperField(this, proto.user.Outages, 5));
};


/**
 * @param {?proto.user.Outages|undefined} value
 * @return {!proto.user.CentralPermissions} returns this
*/
proto.user.CentralPermissions.prototype.setOutages = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.CentralPermissions} returns this
 */
proto.user.CentralPermissions.prototype.clearOutages = function() {
  return this.setOutages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.CentralPermissions.prototype.hasOutages = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Level level = 6;
 * @return {?proto.user.Level}
 */
proto.user.CentralPermissions.prototype.getLevel = function() {
  return /** @type{?proto.user.Level} */ (
    jspb.Message.getWrapperField(this, proto.user.Level, 6));
};


/**
 * @param {?proto.user.Level|undefined} value
 * @return {!proto.user.CentralPermissions} returns this
*/
proto.user.CentralPermissions.prototype.setLevel = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.CentralPermissions} returns this
 */
proto.user.CentralPermissions.prototype.clearLevel = function() {
  return this.setLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.CentralPermissions.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Dashboard.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Dashboard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Dashboard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Dashboard.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    statistics: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Dashboard}
 */
proto.user.Dashboard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Dashboard;
  return proto.user.Dashboard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Dashboard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Dashboard}
 */
proto.user.Dashboard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatistics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Dashboard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Dashboard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Dashboard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Dashboard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool page = 1;
 * @return {boolean}
 */
proto.user.Dashboard.prototype.getPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.Dashboard} returns this
 */
proto.user.Dashboard.prototype.setPage = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.user.Dashboard} returns this
 */
proto.user.Dashboard.prototype.clearPage = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.Dashboard.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool statistics = 2;
 * @return {boolean}
 */
proto.user.Dashboard.prototype.getStatistics = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.Dashboard} returns this
 */
proto.user.Dashboard.prototype.setStatistics = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.user.Dashboard} returns this
 */
proto.user.Dashboard.prototype.clearStatistics = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.Dashboard.prototype.hasStatistics = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Classification.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Classification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Classification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Classification.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Classification}
 */
proto.user.Classification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Classification;
  return proto.user.Classification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Classification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Classification}
 */
proto.user.Classification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Classification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Classification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Classification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Classification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool page = 1;
 * @return {boolean}
 */
proto.user.Classification.prototype.getPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.Classification} returns this
 */
proto.user.Classification.prototype.setPage = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.user.Classification} returns this
 */
proto.user.Classification.prototype.clearPage = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.Classification.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Printouts.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Printouts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Printouts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Printouts.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    validationPrintout: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Printouts}
 */
proto.user.Printouts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Printouts;
  return proto.user.Printouts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Printouts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Printouts}
 */
proto.user.Printouts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValidationPrintout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Printouts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Printouts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Printouts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Printouts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool page = 1;
 * @return {boolean}
 */
proto.user.Printouts.prototype.getPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.Printouts} returns this
 */
proto.user.Printouts.prototype.setPage = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.user.Printouts} returns this
 */
proto.user.Printouts.prototype.clearPage = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.Printouts.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool validation_printout = 2;
 * @return {boolean}
 */
proto.user.Printouts.prototype.getValidationPrintout = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.Printouts} returns this
 */
proto.user.Printouts.prototype.setValidationPrintout = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.user.Printouts} returns this
 */
proto.user.Printouts.prototype.clearValidationPrintout = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.Printouts.prototype.hasValidationPrintout = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ProductionPlanning.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ProductionPlanning.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ProductionPlanning} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ProductionPlanning.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    scheduling: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    recipeApproval: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    recipeApprovalSuper: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ProductionPlanning}
 */
proto.user.ProductionPlanning.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ProductionPlanning;
  return proto.user.ProductionPlanning.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ProductionPlanning} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ProductionPlanning}
 */
proto.user.ProductionPlanning.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setScheduling(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecipeApproval(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecipeApprovalSuper(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ProductionPlanning.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ProductionPlanning.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ProductionPlanning} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ProductionPlanning.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool page = 1;
 * @return {boolean}
 */
proto.user.ProductionPlanning.prototype.getPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.ProductionPlanning} returns this
 */
proto.user.ProductionPlanning.prototype.setPage = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.user.ProductionPlanning} returns this
 */
proto.user.ProductionPlanning.prototype.clearPage = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.ProductionPlanning.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool scheduling = 2;
 * @return {boolean}
 */
proto.user.ProductionPlanning.prototype.getScheduling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.ProductionPlanning} returns this
 */
proto.user.ProductionPlanning.prototype.setScheduling = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.user.ProductionPlanning} returns this
 */
proto.user.ProductionPlanning.prototype.clearScheduling = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.ProductionPlanning.prototype.hasScheduling = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool recipe_approval = 3;
 * @return {boolean}
 */
proto.user.ProductionPlanning.prototype.getRecipeApproval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.ProductionPlanning} returns this
 */
proto.user.ProductionPlanning.prototype.setRecipeApproval = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.user.ProductionPlanning} returns this
 */
proto.user.ProductionPlanning.prototype.clearRecipeApproval = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.ProductionPlanning.prototype.hasRecipeApproval = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool recipe_approval_super = 4;
 * @return {boolean}
 */
proto.user.ProductionPlanning.prototype.getRecipeApprovalSuper = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.ProductionPlanning} returns this
 */
proto.user.ProductionPlanning.prototype.setRecipeApprovalSuper = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.user.ProductionPlanning} returns this
 */
proto.user.ProductionPlanning.prototype.clearRecipeApprovalSuper = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.ProductionPlanning.prototype.hasRecipeApprovalSuper = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Outages.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Outages.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Outages} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Outages.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Outages}
 */
proto.user.Outages.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Outages;
  return proto.user.Outages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Outages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Outages}
 */
proto.user.Outages.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Outages.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Outages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Outages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Outages.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool page = 1;
 * @return {boolean}
 */
proto.user.Outages.prototype.getPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.Outages} returns this
 */
proto.user.Outages.prototype.setPage = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.user.Outages} returns this
 */
proto.user.Outages.prototype.clearPage = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.Outages.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Level.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Level.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Level} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Level.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_super: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Level}
 */
proto.user.Level.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Level;
  return proto.user.Level.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Level} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Level}
 */
proto.user.Level.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuper(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Level.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Level.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Level} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Level.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool super = 1;
 * @return {boolean}
 */
proto.user.Level.prototype.getSuper = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.Level} returns this
 */
proto.user.Level.prototype.setSuper = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.user.Level} returns this
 */
proto.user.Level.prototype.clearSuper = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.Level.prototype.hasSuper = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.user);
